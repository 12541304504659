import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import SectionComponent from '../../shared/components/SectionComponent';
import {connect} from 'react-redux';

import * as selectors from "../feedbackSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import * as sharedSelectors from "../../shared/sharedSelectors";
import {ApplicationState} from '../../ApplicationState';
import {FeedbackModel} from '../FeedbackModels';
import {AgentFilterModel, KeyValueModel, UserProfileModel} from '../../shared/SharedModels';
import {getAllFeedback, toggleFeedbackIsArchived} from '../feedbackActions';
import {getAgentsForFilter} from '../../lookups/lookupActions';
import {getProfile, setIsSearching} from '../../shared/sharedActions';
import {appSettings} from "../../config/appSettings";
import useDebounce from '../../shared/helpers/debounceHook';
import Icon from '../../shared/components/Icon';
import ClockIcon from "../../svg/feedback/clock.svg";
import CustomerIcon from "../../svg/feedback/customer.svg";
import AgentIcon from "../../svg/office-worker.jsx";
import ListIcon from "../../svg/feedback/list.svg";
import MagnifierIcon from "../../svg/feedback/magnifier.svg";
import MessageIcon from "../../svg/feedback/message.svg";
import SmileyIcon from "../../svg/feedback/smiley.svg";
import {Route} from 'react-router';
import {redirect} from '../../shared/router/routerActions';
import ViewMessagePopup from './ViewMessageComponent';
import ArchiveMessagePopup from './ArchiveMessageComponent';
import {Redirect, useHistory} from "react-router-dom";

const staticDataUrl = appSettings.app.staticDataUrl;

type FeedbackProps = {
    feedback: FeedbackModel[],
    ratingFilter: KeyValueModel[],
    agentFilter: AgentFilterModel[],
    dataRangeFilter: KeyValueModel[],
    profile: UserProfileModel | undefined,

    getAllFeedback: typeof getAllFeedback;
    getAgentsForFilter: typeof getAgentsForFilter;
    setIsSearching: typeof setIsSearching;
    redirect: typeof redirect;
    getProfile: typeof getProfile;
    toggleFeedbackIsArchived: typeof toggleFeedbackIsArchived,
};

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        border: '2px solid #fff',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
    },
    tableRow: {
        height: 30,
    },
    cell: {
        minWidth: "150px",
    },
    msgCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    nameCell: {
        width: "fit-content",
        whiteSpace: "nowrap",
    },
    archiveBtn: {
        fontWeight: 'bold',
        color: "#FF0000",
        border: '1px solid',
        borderRadius: '35px',
        borderColor: '#FF0000',
        padding: '5px 15px',
        marginLeft: '3px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: ' rgba(0, 0, 0, 0.1)',
        },
    },
    unArchiveBtn: {
        whiteSpace: 'normal',
        fontWeight: 'bold',
        color: "#66bb6a",
        border: '1px solid',
        borderRadius: '35px',
        borderColor: '#66bb6a',
        marginLeft: '3px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: ' rgba(0, 0, 0, 0.1)',
        },
    },
    btnCell: {
        minWidth: '200px',
    }
}));

const Feedback: React.FC<FeedbackProps> = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const {profile, feedback, ratingFilter, agentFilter, dataRangeFilter, toggleFeedbackIsArchived} = props;
    const [searchString, setSearchString] = useState('');
    const [redirect, setRedirect] = useState('');
    const debouncedSearchString = useDebounce(searchString, 500);

    console.log('feedback', feedback);

    const organization = profile?.organization;

    const initialFilters = {
        rating: '',
        agent: '',
        dataRange: "",
        searchString: debouncedSearchString,
    };
    const [filters, setFilters] = useState(initialFilters);

    const [archivedStateChanged, setArchivedStateChanged] = useState(false)

    const [showArchivedFeedback, setShowArchivedFeedback] = useState(false)

    const initialMsgPopupState = {
        open: false,
        message: "",
    };
    const [messagePopupState, setMessagePopupState] = useState(initialMsgPopupState);

    useEffect(() => {
        // reset the redirect to avoid a loop
        if (redirect.length > 0) {
            setRedirect('');
        }
    }, [redirect]);

    useEffect(() => {
        props.getAllFeedback(filters);
    }, [filters, archivedStateChanged, organization]);

    useEffect(() => {
        if(!agentFilter.length) props.getAgentsForFilter();
    }, []);

    useEffect(() => {
        props.setIsSearching(true);

        setFilters(prev => {
            return {
                ...prev,
                searchString: debouncedSearchString,
            }
        });

    }, [debouncedSearchString]);

    useEffect(() => {
        if (!profile) props.getProfile();
    }, []);

    const getEmail = (agentId: number) => {
        return agentFilter.find(agent => agent.key === agentId)?.login;
    }

    const decodeHtml = (html: string) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any; }>) => {
        setShowArchivedFeedback(false)

        if (event.target.name === "searchString") {
            setSearchString(event.target.value);
            return;
        }

        if (event.target.name === 'rating' && event.target.value === 'Archived') {
            setShowArchivedFeedback(true)
        }

        setFilters(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        })
    };

    const handleOnOpenPopup = (feedback: FeedbackModel) => {
        setRedirect(`/org${profile!.organizationId}/customerfeedback/searchresults/${feedback.id}`);
    }

    const handleOnClosePopup = () => {
        setMessagePopupState(initialMsgPopupState);
        history.push(`/org${profile!.organizationId}/customerfeedback`);
    };

    const handleOnOpenArchivePopup = (feedback: FeedbackModel) => {
        let archiveCheck = '';

        (feedback.isArchived) ? archiveCheck = 'undo Archive for this Feedback?' : archiveCheck = 'Archive this Feedback?';

        setMessagePopupState({
            open: true,
            message: "Are you sure you want to " + archiveCheck
        });
        setRedirect(`/org${profile!.organizationId}/customerfeedback/togglearchive/${feedback.id}`);
    }

    const handleGetViewedMessage = (id: number) => {
        const viewedFeedback = feedback.find(f => f.id === id);

        setMessagePopupState({
            open: true,
            message: viewedFeedback ? viewedFeedback.message : "",
        });
    }

    const handleGetArchiveMessage = (id: number) => {
        const viewedFeedback = feedback.find(f => f.id === id);
        if (viewedFeedback) {
            setMessagePopupState({
                open: true,
                message: `Are you sure you want to ${(viewedFeedback!.isArchived) ? 'undo Archive for this Feedback?' : 'Archive this Feedback?'}`
            });
        } else {
           handleOnClosePopup();
        }
        return id;
    }

    const handleConfirmArchive = (id: number) => {
        setArchivedStateChanged(state => !state);
        const viewedFeedback = feedback.find(f => f.id === id);

        if (viewedFeedback) {
            const archivedState = viewedFeedback!.isArchived;

            const data = {
                id: id,
                isArchived: archivedState ? 0 : 1
            };

            toggleFeedbackIsArchived(data);
        }
        handleOnClosePopup();
    }

    return (
        <Box id="feedback-container">
            {redirect ? <Redirect to={redirect}/> : null}
            <Box display='flex' justifyContent="space-between">
                <Box className="filter-container">
                    <Typography>Rating</Typography>
                    <FormControl variant="outlined" margin="dense" className="select-holder filter-list">
                        <InputLabel htmlFor="ratingFilter"></InputLabel>
                        <Select
                            id="ratingFilter"
                            name="rating"
                            value={filters.rating}
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                Show All
                            </MenuItem>
                            {ratingFilter && ratingFilter.map(i =>
                                <MenuItem key={i.key} value={i.key}>{i.value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="filter-container">
                    <Typography>Staff Member</Typography>
                    <FormControl variant="outlined" margin="dense" className="select-holder filter-list">
                        <InputLabel htmlFor="agentFilter"></InputLabel>
                        <Select
                            value={filters.agent}
                            displayEmpty
                            onChange={handleChange}
                            name="agent"
                            id="agentFilter"
                        >
                            <MenuItem value="">
                                Show All
                            </MenuItem>
                            {agentFilter && agentFilter.map(i =>
                                <MenuItem key={i.key} value={i.key}>{i.value} ({i.login})</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="filter-container">
                    <Typography>Data Range</Typography>
                    <FormControl variant="outlined" margin="dense" className="select-holder filter-list">
                        <InputLabel htmlFor="rangeFilter"></InputLabel>
                        <Select
                            id="rangeFilter"
                            name="dataRange"
                            displayEmpty
                            value={filters.dataRange}
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                Show All
                            </MenuItem>
                            {dataRangeFilter && dataRangeFilter.map(i =>
                                <MenuItem key={i.key} value={i.key}>{i.value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <TextField
                    name='searchString'
                    value={searchString}
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Icon src={MagnifierIcon}/>
                            </InputAdornment>
                    }}
                    onChange={handleChange}
                />
            </Box>
            <SectionComponent marginTop="33px">
                RESULTS
            </SectionComponent>
            <TableContainer>
                <Table className={`${classes.table} tbl`} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <AgentIcon className="margin-right" height="13" width="13"/>
                                    <Typography>Staff Member</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={SmileyIcon}/>
                                    <Typography>Rating</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={ClockIcon}/>
                                    <Typography>Date and Time</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={ListIcon}/>
                                    <Typography>Feedback ID</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={CustomerIcon}/>
                                    <Typography>Customer Name</Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={MessageIcon}/>
                                    <Typography>Message</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.btnCell} align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedback?.length > 0 && feedback.map((item) => {
                            let iconSource = `${staticDataUrl}/default/${item.customerSatisfactionType }.png`;
                            if (item.customerSatisfactionType === 'VerySatisfied') {
                                iconSource = `${staticDataUrl}/default/ExceededExpectations.png`;
                            }
                            if (item.customerSatisfactionType === 'VeryDissatisfied') {
                                iconSource = `${staticDataUrl}/default/Dissatisfied.png`;
                            }
                            return (
                                <TableRow className={classes.tableRow} key={item.id}>
                                    <TableCell className={classes.nameCell} align="left">
                                        {item.agentName} <br/>
                                        {getEmail(item.agentId)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <img src={iconSource} alt={`${item.customerSatisfactionType} icon`}/>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    >
                                        {item.createdDate && item.createdDate.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}
                                    </TableCell>
                                    <TableCell align="left">
                                        {item.id}
                                    </TableCell>
                                    <TableCell align="left">
                                        {item.customerName}
                                    </TableCell>
                                    <TableCell
                                        className={classes.msgCell}
                                        align="left"
                                    >
                                        {decodeHtml(item.message)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOnOpenPopup(item)}
                                        >
                                            View
                                        </Button>
                                        {!showArchivedFeedback ?
                                            <Button
                                                variant="outlined"
                                                className={classes.archiveBtn}
                                                onClick={() => handleOnOpenArchivePopup(item)}
                                            >Archive
                                            </Button> :
                                            <Button
                                                variant="outlined"
                                                className={classes.unArchiveBtn}
                                                onClick={() => handleOnOpenArchivePopup(item)}
                                            >Undo Archive
                                            </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*Using routes to show the pop-ups as they are used as links in emails.*/}
            <Route path="/:orgId/customerfeedback/searchresults/:id">
                {feedback?.length > 0 &&
                    <ViewMessagePopup
                        open={true}
                        message={messagePopupState.message}
                        handleClose={handleOnClosePopup}
                        handleGetMessage={handleGetViewedMessage}
                    />
                }
            </Route>
            <Route path="/:orgId/customerfeedback/togglearchive/:id">
                {feedback?.length > 0 &&
                    <ArchiveMessagePopup
                        open={true}
                        message={messagePopupState.message}
                        handleClose={handleOnClosePopup}
                        handleGetArchiveMessage={handleGetArchiveMessage}
                        handleConfirmArchive={handleConfirmArchive}
                    />
                }
            </Route>
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        feedback: selectors.feedbackSelector(state),
        isSearching: sharedSelectors.isSearchingSelector(state),

        ratingFilter: lookupSelectors.ratingFilterSelector(state),
        dataRangeFilter: lookupSelectors.dataRangeFilterSelector(state),
        agentFilter: lookupSelectors.agentsSelector(state),

        profile: sharedSelectors.profileSelector(state),
        viewedFeedback: selectors.viewedFeedbackSelector(state),
    }
}

const mapDispatchToProps = {
    getAllFeedback,
    getAgentsForFilter,
    setIsSearching,
    redirect,
    getProfile,
    toggleFeedbackIsArchived
};

const FeedbackContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Feedback);

export default FeedbackContainer;
