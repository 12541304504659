import QRCodeFeedback from "./components/QRCodeFeedback";
import QRCodeCustomize from "./components/QRCodeCustomize";
import SetupPageStepper from "../SetupPage/components/SetupStepper";
import {Route, Switch} from "react-router-dom";
import {Box} from "@material-ui/core";
import React, {useEffect} from "react";
import {switchNavMenu} from "../shared/sharedActions";
import {ApplicationState} from "../ApplicationState";
import * as sharedSelectors from "../shared/sharedSelectors";
import {redirect} from "../shared/router/routerActions";
import {connect} from "react-redux";

type QRCodeSetupProps = {
    navTabIsSelected: boolean;
    redirect: typeof redirect;
};

const QRCodeSetup = (props: QRCodeSetupProps) => {
    const {navTabIsSelected, redirect} = props;

    useEffect(() => {
        if (!navTabIsSelected) switchNavMenu(true);
    });
    return (
        <Box id="setup-container">
            <SetupPageStepper redirect={redirect} type={"qr-code"}>
                <Switch>
                    <Route
                        path="/:orgid/QRCodeApp/setup/feedback"
                        component={QRCodeFeedback}
                    ></Route>
                    <Route path="/:orgid/QRCodeApp/setup/customize" component={QRCodeCustomize}></Route>
                </Switch>
            </SetupPageStepper>
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        navTabIsSelected: sharedSelectors.navTabSelector(state),
    };
};

const mapDispatchToProps = {switchNavMenu, redirect};

const QRCodeSetupContainer = connect(mapStateToProps, mapDispatchToProps)(QRCodeSetup);

export default QRCodeSetupContainer;