import {call, put, select, take, takeLatest, throttle} from "redux-saga/effects";

import * as api from '../api/index';
import * as actions from './setupActions';
import * as mappers from './setupMappers';
import * as sharedSelectors from '../shared/sharedSelectors';
import * as setupSelectors from './setupSelectors';
import * as errorActions from '../shared/errors/errorActions';
import * as sharedActions from '../shared/sharedActions';
import * as routerActions from '../shared/router/routerActions';
import * as loadingActions from '../shared/loading/loadingStateActions';
import {
    AddAgent,
    AgentProfile,
    AgentWithSnippet,
    ImportUser,
    ReviewSite,
    Template,
    TextTemplate
} from "./SetupPageData";
import {
    AgentProfileModel,
    AgentWithSnippetModel, AutocompleteSuggestion, QRCodeSettingsModel, QRCodeSettingsUpdateModel,
    ReviewSiteModel, ReviewSiteOptionModel,
    TemplateModel, TextTemplateModel, TextTemplateUpdateModel,
} from "./SetupModels";
import {ActionType, getType} from "typesafe-actions";
import {customErrorMapper} from "../shared/errors/ErrorMappers";
import {UserProfileModel} from "../shared/SharedModels";
import {BadRequestError} from "../auth/ApiError";
import {profileSelector} from "../shared/sharedSelectors";
import {submissionRedirectModel} from "../Feedback/FeedbackModels";


export default function* setupSaga() {
    // implement via getType
    yield takeLatest(getType(actions.getAgents), getWhiteLabelAgents);
    yield takeLatest('setup/EDIT_AGENT', editAgent);
    yield takeLatest('setup/ADD_AGENT', addAgent);
    yield takeLatest(getType(actions.getTemplate), getTemplate);
    yield takeLatest(getType(actions.getTextTemplate), getTextTemplate);
    yield takeLatest(getType(actions.updateTemplateTitleSpacing), updateTemplateTitleSpacing);
    yield takeLatest(getType(actions.updateTemplateIconSize), updateTemplateIconSize);
    yield takeLatest(getType(actions.updateTemplateActionSpacing), updateTemplateActionSpacing);
    yield takeLatest(getType(actions.updateTextTemplateTitleColour), updateTextTemplateTitleColour);
    yield takeLatest(getType(actions.updateTextTemplateActionCount), updateTextTemplateActionCount);
    yield takeLatest(getType(actions.updateTextTemplateFontSize), updateTextTemplateFontSize)
    yield takeLatest(getType(actions.updateTextTemplateFontFamily), updateTextTemplateFontFamily)
    yield takeLatest(getType(actions.updateTextTemplateActionFontSize), updateTextTemplateActionFontSize)
    yield takeLatest(getType(actions.updateTextTemplateTitle), updateTextTemplateTitle)
    yield takeLatest(getType(actions.updateTextTemplateLanguage), updateTextTemplateLanguage)
    yield throttle(1000, getType(actions.updateTemplate), updateTemplate);
    yield throttle(1000, getType(actions.updateTextTemplate), updateTextTemplate);
    yield takeLatest(getType(actions.updateTemplateFeedbackOption), updateTemplateFeedbackOption);
    yield takeLatest(getType(actions.importCSV), importCSV);
    yield takeLatest(getType(actions.importFromEmailClient), importFromEmailClient);
    yield takeLatest(getType(actions.getAgentsWithSnippetByOrg), getAgentsWithSnippetByOrg);
    yield takeLatest(getType(actions.emailSurveyToAgent), emailSurveyToAgent);
    yield takeLatest(getType(actions.getAgentSnippet), getAgentSnippet);
    yield takeLatest(getType(actions.getSuggestions), getSuggestions);
    yield takeLatest(getType(actions.toggleReviewSiteIsActive), toggleReviewSiteIsActive);
    yield takeLatest(getType(actions.toggleSurveyFormIsActive), toggleSurveyFormIsActive);
    yield takeLatest(getType(actions.addReviewSite), addReviewSite);
    yield takeLatest(getType(actions.deleteReviewSite), deleteReviewSite);
    yield takeLatest(getType(actions.getReviewSites), getReviewSites);
    yield takeLatest(getType(actions.getReviewSiteOptions), getReviewSiteOptions);
    yield takeLatest(getType(actions.setReviewSiteAllocationPercentage), setReviewSiteAllocationPercentage);
    yield takeLatest(getType(actions.updateSurveySetType), updateSurveySetType)
    yield takeLatest(getType(actions.getPreviewLink), getPreviewLink)
    yield takeLatest(getType(actions.getQRCodeSettings), getQRCodeSettings)
    yield takeLatest(getType(actions.updateQRCodeSettings), updateQRCodeSettings)
    yield takeLatest(getType(actions.updateQRCodeLogo), updateQRCodeLogo)
    yield takeLatest(getType(actions.getQRCodeRedirectLink), getQRCodeRedirectLink)
}

function* getWhiteLabelAgents(action: ActionType<typeof actions.getAgents>) {
    try {
        const profile: UserProfileModel = yield select(profileSelector);

        const organizationId = profile.organization.id;

        const agents: AgentProfile[] = yield call(api.getAgents, organizationId);
        const model: AgentProfileModel[] = agents.map((agent: AgentProfile) => mappers.agentToModel(agent));
        yield put(actions.getAgentsCompleted(model));

    } catch (e) {

    } finally {

    }
}

function* getAgentsWithSnippetByOrg(action: ActionType<typeof actions.getAgentsWithSnippetByOrg>) {
    try {
        const profile: UserProfileModel = yield select(profileSelector);

        const organizationId = profile.organization.id;

        const agents: AgentWithSnippet[] = yield call(api.getAgentsWithSnippetByOrg, organizationId);
        const model: AgentWithSnippetModel[] = agents.map((agent: AgentWithSnippet) => mappers.agentWithSnippetToModel(agent));
        yield put(actions.getAgentsWithSnippetByOrgCompleted(model));

    } catch (e) {

    } finally {

    }
}

function* getAgentSnippet(action: ActionType<typeof actions.getAgentSnippet>) {
    const agentId = action.payload;

    try {
        const response: Response = yield call(() => api.getAgentSnippet(agentId));

        if (response) {
            const data: AgentWithSnippet[] = yield call(() => response);
            const model: AgentWithSnippetModel[] = data.map((agent: AgentWithSnippet) => mappers.agentWithSnippetToModel(agent));
            yield put(actions.getAgentsWithSnippetByOrgCompleted(model));
        }

    } catch (e) {

    } finally {

    }
}

function* editAgent(action: ActionType<typeof actions.editAgent>) {
    try {
        const model = action.payload;
        // fields validation
        const data = mappers.agentFromModel(model);

        const agent: AgentProfile = yield call(() => api.updateAgent(data));
        yield put(actions.editAgentCompleted(model));
        yield put(actions.changeSaveTemplateFlag(true));

    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

function* addAgent(action: ActionType<typeof actions.addAgent>) {
    try {
        const model = action.payload;

        // fields validation
        const data: AddAgent = mappers.addAgentFromModel(model);
        const agent: AgentProfile = yield call(api.addAgent, data);

        const agentModel = mappers.agentToModel(agent);
        yield put(actions.addAgentCompleted(agentModel));

    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

function* getTemplate(action: ActionType<typeof actions.getTemplate>) {

    loadingActions.begin();

    try {
        const template: Template = yield call(api.getTemplate);

        const textTemplate: TextTemplate = yield call(api.getTextTemplate)

        const model: TemplateModel = mappers.templateToModel(template);
        yield put(actions.getTemplateCompleted(model));

    } catch (e) {

        loadingActions.reset();

    } finally {
        loadingActions.complete();
    }
}

function* getTextTemplate(action: ActionType<typeof actions.getTextTemplate>) {
    loadingActions.begin();

    try {
        const textTemplate: TextTemplate = yield call(api.getTextTemplate)

        const model: TextTemplateModel = mappers.textTemplateToModel(textTemplate);
        yield put(actions.getTextTemplateCompleted(model));

    } catch (e) {

        loadingActions.reset();

    } finally {
        loadingActions.complete();
    }
}

function* updateTemplateTitleSpacing(action: ActionType<typeof actions.updateTemplateTitleSpacing>) {
    const template: TemplateModel = yield select(setupSelectors.templateSelector);
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector)
    const spacingId = action.payload.id; // todo: Use this value to update the text survey spacing

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTemplate({...template, titleSpacing: action.payload.value}));

    yield put(actions.updateTextTemplate({
        ...restTextTemplate,
        fontSizeId: titleFontSizeId,
        titleSpacingId: spacingId
    }))
}

function* updateTemplateIconSize(action: ActionType<typeof actions.updateTemplateIconSize>) {
    const template: TemplateModel = yield select(setupSelectors.templateSelector);
    yield put(actions.updateTemplate({...template, iconSize: action.payload}));
}

function* updateTemplateActionSpacing(action: ActionType<typeof actions.updateTemplateActionSpacing>) {
    const template: TemplateModel = yield select(setupSelectors.templateSelector);
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector)

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTemplate({...template, iconSpacing: action.payload.value}));

    yield put(actions.updateTextTemplate({
        ...restTextTemplate,
        fontSizeId: titleFontSizeId,
        spacingId: action.payload.id
    }))
}

function* updateTextTemplateTitleColour(action: ActionType<typeof actions.updateTextTemplateTitleColour>) {

    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({
        ...restTextTemplate,
        fontSizeId: titleFontSizeId,
        titleColour: action.payload
    }))
}

function* updateTextTemplateTitle(action: ActionType<typeof actions.updateTextTemplateTitle>) {
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({...restTextTemplate, fontSizeId: titleFontSizeId, title: action.payload}))
}

function* updateTextTemplateFontSize(action: ActionType<typeof actions.updateTextTemplateFontSize>) {
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({...restTextTemplate, fontSizeId: action.payload}))
}

function* updateTextTemplateFontFamily(action: ActionType<typeof actions.updateTextTemplateFontFamily>) {
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({
        ...restTextTemplate,
        fontSizeId: titleFontSizeId,
        fontFamilyId: action.payload
    }))
}

function* updateTextTemplateActionCount(action: ActionType<typeof actions.updateTextTemplateActionCount>) {
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({...restTextTemplate, fontSizeId: titleFontSizeId, ...action.payload}))
}

function* updateTextTemplateActionFontSize(action: ActionType<typeof actions.updateTextTemplateActionFontSize>) {
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({
        ...restTextTemplate,
        fontSizeId: titleFontSizeId,
        actionFontSizeId: action.payload
    }))
}

function* updateTextTemplateLanguage(action: ActionType<typeof actions.updateTextTemplateLanguage>) {
    const textTemplate: TextTemplateModel = yield select(setupSelectors.textTemplateSelector);

    const {
        id,
        organizationId,
        titleId,
        titleFontSizeId,
        titleFontSize,
        fontFamily,
        spacing,
        titleSpacing,
        actionCount,
        typeId,
        ...restTextTemplate
    } = textTemplate;

    yield put(actions.updateTextTemplate({
        ...restTextTemplate,
        fontSizeId: titleFontSizeId,
        languageId: action.payload
    }))
}

function* updateTemplate(action: ActionType<typeof actions.updateTemplate>) {
    try {
        const data = mappers.templateFromModel(action.payload);

        const template: Template = yield call(api.updateTemplate, data);

        yield put(actions.updateTemplateCompleted(action.payload));

    } catch (e) {

    } finally {

    }
}

function* updateTextTemplate(action: ActionType<typeof actions.updateTextTemplate>) {
    try {
        const textTemplate: TextTemplateUpdateModel = yield call(api.updateTextTemplate, action.payload)

    } catch (e) {

    }

    finally {
        yield put(actions.getTextTemplate())
    }
}

function* updateTemplateFeedbackOption(action: ActionType<typeof actions.updateTemplateFeedbackOption>): any {
    try {
        const data = mappers.templateFeedbackOptionFromModel(action.payload);

        const template: TemplateModel = yield call(api.updateTemplateFeedbackOption, data);
        yield put(actions.updateTemplateCompleted(template));
        yield put(actions.setReviewSiteAllocationPercentage({reviewSites: null}));
    } catch (e) {
        console.error(e);

    } finally {

    }
}

function* importCSV(action: ActionType<typeof actions.importCSV>) {
    const csv = action.payload;
    yield put(loadingActions.beginScope("import"));

    try {
        const importUsers: ImportUser[] = yield call(api.uploadCSV, csv);
        const model = importUsers.map(user => mappers.importUserToModel(user));

        const profile: UserProfileModel = yield select(sharedSelectors.profileSelector);
        yield put(actions.getAgents());

        // need to handle it right (!!!)
        yield take(getType(actions.getAgentsCompleted));
        yield put(actions.importCompleted(model));

    } catch (e) {
        const err = customErrorMapper(e.message);
        yield put(errorActions.addError(err));

        yield put(loadingActions.resetScope("import"));
    } finally {

        yield put(loadingActions.completeScope("import"));
    }
}

function* importFromEmailClient(action: ActionType<typeof actions.importFromEmailClient>) {
    yield put(loadingActions.begin());
    const reqParams = action.payload;

    try {
        const importUsers: ImportUser[] = yield call(api.importFromEmailClient, reqParams.client, reqParams.code);

        const model = importUsers.map(user => mappers.importUserToModel(user));
        yield put(actions.importCompleted(model));
    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

        yield put(loadingActions.reset());
    } finally {
        yield put(sharedActions.getProfile());
        // yield take(getType(sharedActions.loadUserProfileCompleted));
        // const profile: UserProfileModel = yield select(sharedSelectors.profileSelector);
        //
        // yield put(actions.getAgents());
        //
        // need to handle it right (!!!)
        // yield take(getType(actions.getAgentsCompleted));
        // yield put(routerActions.redirect(`/org${profile.organizationId}/setup/AgentImport/ConnectEmailClient`));

        yield put(loadingActions.complete());
    }
}


function* emailSurveyToAgent(action: ActionType<typeof actions.emailSurveyToAgent>) {
    yield put(loadingActions.begin());
    const agentId = action.payload;

    try {
        yield call(() => api.emailSurveyToAgent(agentId));
        yield put(actions.setAlertNotification(true));
    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}


function* getSuggestions(action: ActionType<typeof actions.getSuggestions>) {
    try {
        // @ts-ignore
        const result = yield call(api.getSuggestions, action.payload)

        const predictions = result.predictions;

        const suggestions = predictions.map((x: AutocompleteSuggestion) => {
            return {
                description: x.description,
                place_id: x.place_id,
            }
        })

        yield put(actions.getSuggestionsCompleted(suggestions))
    } catch (e) {
        console.log(e)
    } finally {

    }
}


function* toggleReviewSiteIsActive(action: ActionType<typeof actions.toggleReviewSiteIsActive>) {

    try {
        const model = action.payload.reviewSite;
        const type = action.payload.type;

        yield call(() => api.toggleReviewSiteIsActive(model));

        yield put(actions.toggleReviewSiteIsActiveCompleted({reviewSite: model, type}));

        yield put(actions.setReviewSiteAllocationPercentage({reviewSites: null, type}));
    } catch (e) {

    } finally {

    }
}


function* toggleSurveyFormIsActive(action: ActionType<typeof actions.toggleSurveyFormIsActive>) {

    try {
        const model = action.payload;

        yield call(() => api.toggleSurveyFormIsActive(model));

        yield put(actions.toggleSurveyFormIsActiveCompleted(model.surveyFormIsActive));

    } catch (e) {

    } finally {

    }
}


function* addReviewSite(action: ActionType<typeof actions.addReviewSite>) {

    try {
        const model = action.payload.reviewSite;
        const type = action.payload.type;

        if (type === "qr-code") {
            const reviewSite: ReviewSiteModel = yield call(api.addQRReviewSite, model);
            yield put(actions.setAlertNotification(true));
            yield put(actions.addQRReviewSiteCompleted(reviewSite));

            yield put(actions.setReviewSiteAllocationPercentage({reviewSites: null, type}));
        } else {
            const reviewSite: ReviewSiteModel = yield call(api.addReviewSite, model);
            yield put(actions.setAlertNotification(true));
            yield put(actions.addReviewSiteCompleted(reviewSite));

            yield put(actions.setReviewSiteAllocationPercentage({reviewSites: null, type}));
        }

    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {
    }
}


function* deleteReviewSite(action: ActionType<typeof actions.deleteReviewSite>) {
    const id = action.payload.id;
    const type = action.payload.type;

    try {
        yield call(() => api.deleteReviewSite(id));
        yield put(actions.deleteReviewSiteCompleted({id, type}));
        yield put(actions.setReviewSiteAllocationPercentage({reviewSites: null, type: "qr-code"}));
    } catch {

    } finally {

    }
}

function* setReviewSiteAllocationPercentage(action: ActionType<typeof actions.setReviewSiteAllocationPercentage>): any { // Select effect doesn't like typescript
    let reviewSites = action.payload.reviewSites;
    const type = action.payload.type;
    if (!reviewSites && type === "email-signature") {
        reviewSites = yield select(setupSelectors.reviewSiteSelector);
    } else if (!reviewSites && type === "qr-code") {
        reviewSites = yield select(setupSelectors.qrReviewSiteSelector);
    }
    if (reviewSites) {
        const currentTemplate = yield select(setupSelectors.templateSelector);
        let activeReviewSiteCount = 0;
        activeReviewSiteCount = reviewSites.filter((site: ReviewSiteModel) => site.isActive).length;
        try {
            let updatedSites = reviewSites.map((site: ReviewSiteModel) => {
                const percentage = 100 / activeReviewSiteCount;
                const number = percentage.toFixed(2);
                site.allocationPercentage = "100";
                if (isFinite(+number)) {
                    site.allocationPercentage = number;
                }
                return site;
            });

            if (type === "qr-code") {
                yield put(actions.getQRReviewSitesCompleted(updatedSites));
            } else {
                yield put(actions.getReviewSitesCompleted(updatedSites));
            }
        } catch (e) {
            console.error(e);
        } finally {

        }
    }
}

function* getReviewSites(action: ActionType<typeof actions.getReviewSites>) {
    try {

        if (action.payload === "qr-code") {
            const reviewSites: ReviewSite[] = yield call(api.getQRReviewSites);

            yield put(actions.setReviewSiteAllocationPercentage({reviewSites, type: action.payload}));
        } else {
            const reviewSites: ReviewSite[] = yield call(api.getReviewSites);
            // before we set the review sites, we need to set the allocation percentage
            yield put(actions.setReviewSiteAllocationPercentage({reviewSites, type: action.payload}));
        }


    } catch (e) {

    } finally {

    }
}


function* getReviewSiteOptions(action: ActionType<typeof actions.getReviewSiteOptions>) {
    try {
        // @ts-ignore
        const result = yield call(api.getReviewSiteOptions);
        const reviewSitesOptions: ReviewSiteOptionModel[] = result.map((res: ReviewSiteOptionModel) => mappers.getReviewSiteOptionFromModel(res));
        const filteredOptions = reviewSitesOptions.filter((result: ReviewSiteOptionModel) => result.name !== 'Survey')

        yield put(actions.getReviewSiteOptionsCompleted(filteredOptions));

    } catch (e) {

    } finally {

    }
}

function* updateSurveySetType(action: ActionType<typeof actions.updateSurveySetType>) {
    try {
        yield call(api.updateSurveySetType, action.payload)
    } catch (e) {

    }
}

function* getPreviewLink(action: ActionType<typeof actions.getPreviewLink>) {
    try {
        const data: string = yield call(api.getPreviewLink)

        yield put(actions.getPreviewLinkCompleted(data));

    } catch (e) {
        console.error(e);
    }
}

function* getQRCodeSettings(action: ActionType<typeof actions.getQRCodeSettings>) {
    try {
        const data: QRCodeSettingsModel = yield call(api.getQRCodeSettings);

        yield put(actions.getQRCodeSettingsCompleted(data))
    }

    catch (e) {
        console.error(e);
    }
}

function* updateQRCodeSettings(action: ActionType<typeof actions.updateQRCodeSettings>) {
    try {
        const data: QRCodeSettingsUpdateModel = action.payload;

        yield call(api.updateQRCodeSettings, data)
    }

    catch (e) {
        console.error(e)
    }

    finally {
        yield put(actions.getQRCodeSettings())
    }
}

function* updateQRCodeLogo(action: ActionType<typeof actions.updateQRCodeLogo>) {
    try {
        const data: File = action.payload;

        const response: Response = yield call(api.updateQRCodeLogo, data)

        if (response) {
            const qrCodeSettings: QRCodeSettingsModel = yield call(() => response);
            yield put(actions.updateQRCodeLogoCompleted(qrCodeSettings.logoData))
        }
    } catch (e) {
        console.error(e)
    }
}

function* getQRCodeRedirectLink(action: ActionType<typeof actions.getQRCodeRedirectLink>) {
    try {
        const response: Response = yield call(api.getQRCodeRedirectLink, action.payload)

        if (response) {
            const submissionRedirect: submissionRedirectModel = yield call(() => response)
            yield put(actions.getQRCodeRedirectLinkCompleted(submissionRedirect.url))
        }
    } catch (e) {
        console.error(e)
    }
}