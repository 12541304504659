import {call, put, race, take} from "redux-saga/effects";
import {getExpirationDate, isExpired} from './_helpers/commonFunctions';
import * as authActions from "./redux/actions";
import * as sharedActions from "../shared/sharedActions";
import {TokenPair} from "./redux/reducers";
import {ApiError, BadRequestError, UnauthorizedError, NotFoundRequestError} from "./ApiError";

type ErrorResponseBody = { [key: string]: string };

export function* getToken(apiName: string) {
    yield put(authActions.requestToken(apiName));

    const {receivedToken, middlewareError} = yield race({
        receivedToken: take(authActions.receiveToken),
        middlewareError: take(authActions.middlewareError),
    });

    if (receivedToken) {
        if (receivedToken.payload.apiName === apiName) return receivedToken.payload.token;
    } else if (middlewareError) {
        if (middlewareError.payload.apiName === apiName) throw middlewareError.payload.err;
    }
    // todo: verify if this is correct
}

function* handleResponse(response: Response) {
    // Clone the response
    const clonedResponse = response.clone();

    if (response.status === 200) {
        const textResponse: string = yield response.text();
        try {
            return JSON.parse(textResponse);
        } catch {
            return textResponse;
        }
    }

    if (response.status === 400) {
        console.log('handleResponse ...400');
        let body: ErrorResponseBody;
        try {
            body = yield call(() => response.json()) as unknown as ErrorResponseBody;
        } catch {
            // Use the cloned response here
            const bodyText: string = yield call(() => clonedResponse.text());
            body = {message: bodyText} as ErrorResponseBody;
        }

        const msg = body["message"];

        throw new BadRequestError(msg);
    }

    // Handle other status codes...

    console.log('handleResponse ...none');
    const text: string = yield response.text();

    throw new ApiError(text);
}

export const fetchBase = function* (method: string, path: string, apiName: string, opts?: any, retry?: boolean): any {

    const token = yield call(getToken, apiName);
    const tokenString = token ? token : '';

    const headers = opts && opts.headers ? opts.headers : {};

    // response after adding authorization header
    let response = yield fetch(path, {
        method,
        ...opts,
        headers: {
            "Authorization": "Bearer " + tokenString,
            // "Content-Type": "application/json",
            ...headers,
        }
    });

    // todo: verify response status
    if (!response.ok) {

        console.log(`response not ok ${response.status}`)
        if ([401, 403].indexOf(response.status) !== -1 && !retry) {
            yield put(authActions.removeTokens());
            // token expired
            response = yield fetchBase(method, path, apiName, opts, true);
        }
    }

    return yield call(() => handleResponse(response));
}

export const login = (apiName: string, tokens: TokenPair) => {
    const json = JSON.stringify(tokens);

    authActions.setTokens(tokens);
    localStorage.setItem(apiName, json);
};

export function* logout(apiName: string) {
    yield put(authActions.removeTokens());
    yield put(sharedActions.clearStore());

    localStorage.removeItem(apiName);
    // FIXME: HARDCODED BAD
    localStorage.removeItem('primaryToken');
}

export function* get<T>(apiName: string, resourceUrl: string) {
    const result: Response = yield fetchBase("GET", resourceUrl, apiName);
    return result;
}

export function* post<T>(apiName: string, resourceUrl: string, data: T) {
    const options = {
        body: data ? data : undefined,
        // headers: {
        //     "Content-Type": "application/json",
        // }
    };

    const result: Response = yield fetchBase('POST', resourceUrl, apiName, options);
    return result;
}

export function* putNoResult<T>(apiName: string, resourceUrl: string, data: T) {
    const options = {
        body: data ? (data instanceof FormData ? data : JSON.stringify(data)) : undefined,
        // body: data ? JSON.stringify(data) : undefined,
        // headers: {
        //     "Content-Type": "application/json",
        // }data instanceof FormData ? data : JSON.stringify(data)
    };

    const result: Response = yield fetchBase('PUT', resourceUrl, apiName, options);
    return result;
}

export function* deleteNoResult<T>(apiName: string, resourceUrl: string) {
    const opts = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const result: Response = yield fetchBase('DELETE', resourceUrl, apiName, opts);
    return result;
}
