import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import Icon from "../../shared/components/Icon";
import ArrowIcon from '../../svg/arrowbtn.svg';
import { useStepperState } from "./SetupStepper";
import {useStepperState as useQRCodeStepperState} from "./SetupStepper";
import { useLocation } from "react-router-dom";

type SetupStepperStep = {
    label: string,
    path: string,
};

type SetupStepperButtonsProps = {
    activeStep: number,
    steps: SetupStepperStep[],

    handleNext: () => void,
    handleBack: () => void,
    handleReset: () => void,
    isQRCodeStepper?: boolean
};

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
        color: '#FFF',
        backgroundColor: '#F7BA00',
        fontWeight: 'bold',
        "&:hover": {
            backgroundColor: '#a98001',
            boxShadow: 'none'
        },
        "&.MuiButton-outlinedPrimary": {
            color: '#F7BA00',
            backgroundColor: '#FFF',
            borderColor: '#F7BA00',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            "&:hover": {
                color: '#FFF',
                backgroundColor: '#a98001',
                boxShadow: 'none'
            },
        }
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const SetupStepperButtons: React.FC<SetupStepperButtonsProps> = (props) => {
    const { activeStep, steps, isQRCodeStepper } = props;
    const emailSignatureStepperState = useStepperState();
    const qrCodeStepperState = useQRCodeStepperState();
    const stepperState = isQRCodeStepper ? qrCodeStepperState : emailSignatureStepperState;
    const classes = useStyles();
    const location = useLocation();

    const isQRCustomizePage = /\/QRCodeApp\/setup\/customize/.test(location.pathname)

    const codeBlock = (
        activeStep === steps.length ? (
            <div>
                <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={props.handleReset} className={classes.button}>
                    Reset
                </Button>
            </div>
        ) : (
            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    style={{display: activeStep === 0 ? "none" : ""}}
                    onClick={props.handleBack}
                    className={classes.button}
                >
                    Back
                </Button>
                {activeStep !== steps.length - 1 ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.handleNext}
                        className={classes.button}
                        endIcon={Icon({src: ArrowIcon})}
                        disabled={!stepperState.canGoForward}
                    >
                        Next
                    </Button>
                ) : null}
            </div>
        )
    );

    return (
        <Box
            style={{
                textAlign: "right",
                display: isQRCustomizePage ? "flex" : "block",
                justifyContent: isQRCustomizePage ? "center" : "flex-start"
            }}
        >
            {isQRCustomizePage ? (
                <div style={{width: "860px"}}>{codeBlock}</div>
            ) : (
                codeBlock
            )}
        </Box>
    );
}

export default SetupStepperButtons;
