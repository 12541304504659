import {connect} from "react-redux";
import {Box, Button, TextField, Typography} from "@material-ui/core";
import SectionComponent from "../../shared/components/SectionComponent";
import React, {useEffect, useState, useRef} from "react";
import {
    uploadLogo
} from "../../SettingsPage/settingsActions";
import QRCodeLogo from "./QRCodeLogo";
import downloadIcon from "../../svg/download-icon.svg";
import {ApplicationState} from "../../ApplicationState";
import CustomRadioButton from "../../shared/components/CustomRadioButton";
import {makeStyles} from "@material-ui/core/styles";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import {KeyValueModel, OrganizationModel} from "../../shared/SharedModels";
import {loadColorPalette} from "../../lookups/lookupActions";
import {TemplateColorTypes} from "../../SetupPage/setupEnums";
import {QRCode} from 'react-qrcode-logo';
import {
    getQRCodeSettings,
    updateQRCodeSettings
} from "../../SetupPage/setupActions";
import * as setupSelectors from "../../SetupPage/setupSelectors";
import {QRCodeSettingsModel} from "../../SetupPage/SetupModels";
import {appSettings} from "../../config/appSettings";
import * as sharedSelectors from "../../shared/sharedSelectors";
import Loading from "../../shared/components/Loading";

type QRCodeCustomize = {
    uploadLogo: typeof uploadLogo;
    colorPalette: KeyValueModel[],
    loadColorPalette: typeof loadColorPalette,
    getQRCodeSettings: typeof getQRCodeSettings,
    qrCodeSettings: QRCodeSettingsModel,
    updateQRCodeSettings: typeof updateQRCodeSettings,
    organization?: OrganizationModel,
    qrCodeLogoData: string
}

type CircleProps = {
    color: TemplateColorTypes,
    active: boolean,
};

type HexCircleProps = {
    color: string,
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: "",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    emailClients: {
        display: "flex",
    },
    circle: {
        borderRadius: "50%",
        marginRight: "4px",
        height: "25px",
        minWidth: "25px",
        display: "inline-block",
    },
    active: {
        border: "2px solid #4B5A7A",
        boxShadow: "0 0 10px rgba(0,0,0,0.2);",
    },
    selectWidth: {
        width: "100%",
    },
    language: {
        display: "flex",
        alignItems: "center",
    },
    infoIcon: {
        paddingLeft: "1rem",
        color: "#3B76EF"
    },
    ctaGroup: {
        display: "flex",
    },
    editBtn: {
        margin: "8px 0 4px 0",
        marginLeft: '3px',
    },
    colorBtns: {
        display: "flex",
        flexDirection: "column",
    },
    hexInputGroup: {
        display: "flex",
        flexDirection: "row",
        marginTop: "8px",
        marginBottom: "none",
        alignItems: "center",
    },
    hexInput: {
        marginRight: "10px",
    },
    customIcons: {
        display: "flex",
        flexDirection: "column",
    },
    smlSelect: {
        paddingLeft: '1rem'
    },
    customize: {
        display: "flex",
        justifyContent: "space-between",
        padding: "30px",
        backgroundColor: "#FFF"
    },
    colorPalette: {
        display: "flex",
        gridTemplateColumns: "repeat(8, 1fr)",
        margin: "12px 0",
        flexWrap: "wrap"
    },
    colorBtn: {
        boxSizing: "border-box",
        width: "30px",
        height: "30px",
        alignItems: "center",
        textAlign: "center",
        "&.active": {
            borderColor: "transparent"
        }
    }
}));

const Circle = (props: CircleProps) => {
    const classes = useStyles();

    return (
        <div className={`${classes.circle} ${props.active ? classes.active : ''}`}
             style={{backgroundColor: props.color}}/>
    )
}

const HexCircle = (props: HexCircleProps) => {
    const classes = useStyles();

    return (
        <div className={`${classes.circle}`}
             style={{backgroundColor: props.color}}/>
    )
}

const QRCodeCustomizePage = (props: QRCodeCustomize) => {
    const classes = useStyles();
    const {
        colorPalette,
        loadColorPalette,
        qrCodeSettings,
        getQRCodeSettings,
        updateQRCodeSettings,
        organization,
        qrCodeLogoData
    } = props;

    const qrCodeRef = useRef<QRCode | null>(null)
    const [qrCodeBase64, setQRCodeBase64] = useState<string | null>(null)

    const qrCodeLink = `${appSettings.clientApp.baseAddress}/ext/r/${organization?.guid}`

    const generateBase64 = () => {
        const canvas = document.querySelector("#react-qrcode-logo") as HTMLCanvasElement;
        if (!canvas) return;
        const base64String = canvas?.toDataURL("image/jpeg", 1.0).split(",")[1];
        setQRCodeBase64(base64String)
    }

    useEffect(() => {
        if (!colorPalette.length) loadColorPalette();
    }, [])

    const [hexInputIsValid, setHexInputIsValid] = useState(true);

    const initialInputFields = {
        hexInput: qrCodeSettings.colour,
    }

    const [inputFields, setInputFields] = useState(initialInputFields)

    const handleChange = async (event: React.ChangeEvent<{ id?: string, name?: string; value: any }>) => {
        setHexInputIsValid(true);

        if (event.target.id === 'hexInput') {
            // Check if the hex input is valid
            if (!(event.target.value.match(/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/) && (event.target.value.length > 3))) {
                // Check if the hex input is different from the current color
                // Do not combine these checks into one if statement as it will set the input field to invalid if the values are the same
                setHexInputIsValid(false)
                window.alert('Please enter a valid hexadecimal color code e.g. #3498db')
            }
        }
        updateQRCodeSettings({colour: event.target.value, feedbackOption: qrCodeSettings.feedbackOption})
    }
    const handleInput = (event: React.ChangeEvent<{ id?: string; value: any }>) => {
        if (event.target.value.length > 0 && event.target.value.length < 8) {
            setInputFields(prevState => {
                return {
                    ...prevState,
                    [event.target.id!]: event.target.value,
                }
            });
        }
    };

    const handleDownload = () => {
        const canvas = document.querySelector("#react-qrcode-logo") as HTMLCanvasElement;
        if (!canvas) {
            return;
        }
        const link = document.createElement("a");
        link.download = `rms-org${qrCodeSettings.organizationId}-qrcode.jpg`;
        link.href = canvas?.toDataURL("image/jpeg", 1.0);
        link.click();
    };

    useEffect(() => {
        getQRCodeSettings()
    }, [])

    useEffect(() => {
        setInputFields({hexInput: qrCodeSettings.colour});
    }, [qrCodeSettings.colour]);

    useEffect(() => {
        if (qrCodeBase64) {
            if (organization?.id === qrCodeSettings.organizationId) {
                    updateQRCodeSettings({
                        colour: qrCodeSettings.colour,
                        qrCode: qrCodeBase64,
                        feedbackOption: qrCodeSettings.feedbackOption
                    })
            }
        }
    }, [qrCodeBase64]);

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div>
                <Box style={{display: "flex"}}>
                    <Box style={{minWidth: "400px", marginRight: "40px"}}>
                        <SectionComponent marginTop="30px">Preview</SectionComponent>
                        <Box style={{
                            minHeight: "263px",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            padding: "16px 0"
                        }}>
                            {organization?.id === qrCodeSettings.organizationId ?
                                <QRCode value={qrCodeLink} size={210} ref={qrCodeRef}
                                        fgColor={qrCodeSettings.colour} logoPadding={1} logoPaddingStyle={"circle"}
                                        logoImage={qrCodeLogoData ? `data:image;base64,${qrCodeLogoData}` : ""}
                                        logoOpacity={1} bgColor={"#FFF"} enableCORS={true}
                                        logoOnLoad={generateBase64}/> : <Loading message={" "}/>}
                        </Box>
                    </Box>
                    <Box style={{minWidth: "400px"}}>
                        <SectionComponent marginTop="30px">Download Options</SectionComponent>
                        <Box style={{
                            minHeight: "263px",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Button style={{
                                backgroundColor: "#24b14b",
                                borderRadius: "5px",
                                padding: "6px 20px",
                                lineHeight: "normal"
                            }} onClick={handleDownload}>
                                <Box style={{marginRight: "10px"}}>
                                    <img src={downloadIcon} alt="download icon"/>
                                </Box>
                                <Box style={{color: "#FFF"}}>
                                    <Typography style={{fontSize: "12px", fontWeight: "bold"}}>DOWNLOAD</Typography>
                                    <Typography style={{fontSize: "12px"}}>JPG</Typography>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box style={{display: "flex"}}>
                    <Box style={{minWidth: "400px", marginRight: "40px"}}>
                        <SectionComponent marginTop="30px">Customize QR Code</SectionComponent>
                        <Box id={"template-container"} style={{maxWidth: "400px"}}>
                            <Box className={`${classes.customize}`}>
                                {colorPalette.length &&
                                    <Box className="colorPalette-container">
                                        <Typography>Color</Typography>
                                        <div className={`${classes.colorBtns}`}>
                                            <Box className={`${classes.colorPalette}`}
                                                 style={{width: "52%", minWidth: "242px"}}>
                                                {colorPalette.map(color =>
                                                    <CustomRadioButton name="color" key={color.key}
                                                                       className={`${classes.colorBtn}`}
                                                                       value={color.value}
                                                                       selected={qrCodeSettings.colour}
                                                                       handleChange={handleChange}>
                                                        <Circle color={color.value}
                                                                active={qrCodeSettings.colour === color.value}/>
                                                    </CustomRadioButton>
                                                )}
                                            </Box>
                                            <Typography>Hex Color</Typography>
                                            <Box className={classes.hexInputGroup}>
                                                <TextField
                                                    className={classes.hexInput}
                                                    name='color'
                                                    value={inputFields.hexInput}
                                                    id='hexInput'
                                                    variant="outlined"
                                                    size="small"
                                                    error={!hexInputIsValid}
                                                    onChange={handleInput}
                                                    onBlur={handleChange}
                                                >{qrCodeSettings.colour}</TextField>
                                                <HexCircle color={qrCodeSettings.colour}/>
                                            </Box>
                                        </div>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{minWidth: "400px"}}>
                        <SectionComponent marginTop="30px">QR Code Logo</SectionComponent>
                        <QRCodeLogo/>
                    </Box>
                </Box>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        colorPalette: lookupSelectors.colorPaletteSelector(state),
        qrCodeSettings: setupSelectors.qrCodeSettingsSelector(state),
        organization: sharedSelectors.organizationSelector(state),
        qrCodeLogoData: setupSelectors.qrCodeLogoDataSelector(state)
    }
}

const mapDispatchToProps = {uploadLogo, loadColorPalette, getQRCodeSettings, updateQRCodeSettings};

const QRCodeCustomize = connect(mapStateToProps, mapDispatchToProps)(QRCodeCustomizePage);

export default QRCodeCustomize;
