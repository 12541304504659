import {Box} from "@material-ui/core";
import React from "react";
import {updateQRCodeLogo} from "../../SetupPage/setupActions";
import {connect} from "react-redux";
import ImageUploadComponent from "../../SettingsPage/components/ImageUploadComponent";

type QRCodeLogoProps = {
    updateQRCodeLogo: typeof updateQRCodeLogo
};

const QRCodeLogoContainer = (props: QRCodeLogoProps) => {
    const {updateQRCodeLogo} = props;
    const uploadFileHandler = (file: File) => {
        if (file) {
            updateQRCodeLogo(file);
        }
        return true;
    };

    return (
        <Box>
            <Box className="logosettings-container content-block" style={{padding: "39px 40px"}}>
                <ImageUploadComponent uploadFileAction={uploadFileHandler} inputLabel={"QR Code Logo"} type={"qr-code"}/>
            </Box>
        </Box>
    )
}

const mapDispatchToProps = {updateQRCodeLogo};

const QRCodeLogo = connect(null, mapDispatchToProps)(QRCodeLogoContainer);

export default QRCodeLogo;
