import SectionComponent from "../../shared/components/SectionComponent";
import React, {useEffect, useState} from "react";
import FeedbackOption from "../../SetupPage/components/FeedbackOption";
import {
    Box,
    Button,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {connect} from "react-redux";
import {ApplicationState} from "../../ApplicationState";
import * as sharedSelectors from "../../shared/sharedSelectors";
import * as setupSelectors from "../../SetupPage/setupSelectors";
import {
    QRCodeSettingsModel,
    ReviewSiteModel,
    ReviewSiteOptionModel,
} from "../../SetupPage/SetupModels";
import Icon from "../../shared/components/Icon";
import PlusIcon from "../../svg/plusbtn.svg";
import editBtnIcon from "../../svg/editbtn.svg";
import {makeStyles} from "@material-ui/core/styles";
import {
    addReviewSite,
    deleteReviewSite,
    getQRCodeSettings,
    getReviewSiteOptions,
    getReviewSites,
    setAlertNotification,
    setFeedbackOptionSavedState,
    toggleReviewSiteIsActive,
    toggleSurveyFormIsActive,
    updateQRCodeSettings
} from "../../SetupPage/setupActions";
import ReviewSiteModal from "../../SetupPage/components/ReviewSiteModal";
import SnackbarComponent from "../../shared/components/Snackbar";
import {UserProfileModel} from "../../shared/SharedModels";
import {useStepperDispatch} from "../../SetupPage/components/SetupStepper";
import {appSettings} from "../../config/appSettings";
import Loading from "../../shared/components/Loading";

type QRCodeFeedbackProps = {
    profile: UserProfileModel | undefined;
    qrReviewSites: ReviewSiteModel[];
    reviewSiteOptions: ReviewSiteOptionModel[];
    toggleReviewSiteIsActive: typeof toggleReviewSiteIsActive;
    toggleSurveyFormIsActive: typeof toggleSurveyFormIsActive;
    setAlertNotification: typeof setAlertNotification;
    getReviewSites: typeof getReviewSites;
    getReviewSiteOptions: typeof getReviewSiteOptions;
    addReviewSite: typeof addReviewSite;
    deleteReviewSite: typeof deleteReviewSite;
    qrCodeSettings: QRCodeSettingsModel,
    getQRCodeSettings: typeof getQRCodeSettings
    updateQRCodeSettings: typeof updateQRCodeSettings;
    setFeedbackOptionSavedState: typeof setFeedbackOptionSavedState;
    feedbackOptionSaved: boolean;
}

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: "250px",
    },
    table: {
        minWidth: 650,
        borderLeft: "2px solid #fff",
        borderRight: "2px solid #fff",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
    },
    tableHeader: {
        height: "45px",
        display: "flex",
        padding: "10px",
        alignItems: "center",
        backgroundColor: "#fff",
        justifyContent: "space-between",
    },
    cell: {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    warningButton: {
        color: "white",
        backgroundColor: "red",
    },
    removeButton: {
        color: "#F43B3B",
        borderColor: "#F43B3B",
        marginLeft: "0.5rem",
        '&:hover': {
            color: "#fa7a7a",
            borderColor: "#fa7a7a",
            backgroundColor: "transparent",
        }
    },
    addButton: {
        whiteSpace: "nowrap",
    },
    previewBtnDiv: {
        margin: "1rem 0",
        display: "flex",
        justifyContent: "center",
    },
    previewBtn: {
        fontWeight: "bold",
        color: "white",
        backgroundColor: '#F7BA00',
        borderRadius: "5px",
        marginTop: "32px",
        padding: "4px 32px",
        "&:hover": {
            backgroundColor: '#a98001',
            boxShadow: 'none'
        }
    },
});

function QRCodeFeedbackPage(props: QRCodeFeedbackProps) {
    const dispatch = useStepperDispatch();
    const classes = useStyles();
    const {
        qrReviewSites,
        reviewSiteOptions,
        profile,
        getReviewSites,
        getReviewSiteOptions,
        addReviewSite,
        deleteReviewSite,
        toggleReviewSiteIsActive,
        toggleSurveyFormIsActive,
        qrCodeSettings,
        getQRCodeSettings,
        updateQRCodeSettings,
        setFeedbackOptionSavedState,
        feedbackOptionSaved
    } = props;

    const organization = profile?.organization;

    const [reviewSiteModalOpen, setReviewSiteModalOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [activeReviewSiteCount, setActiveReviewSiteCount] = useState<number>(0);
    const [selectedReviewSite, setSelectedReviewSite] = useState<ReviewSiteModel | undefined>(undefined);
    const [modalAction, setModalAction] = useState<"" | "edit" | "remove">("");

    useEffect(() => {
        let canGoForward = false;
        if (qrReviewSites.length > 0) {
            canGoForward = true;
        }
        dispatch({type: 'setCanGoForward', payload: canGoForward});
    }, [qrReviewSites]);

    useEffect(() => {
        getReviewSites("qr-code");
        getReviewSiteOptions();
    }, [organization]);

    useEffect(() => {
        if (qrCodeSettings) {
            setSelectedOption(qrCodeSettings.feedbackOption);
        }

    }, [qrCodeSettings]);

    useEffect(() => {
        if (selectedOption !== '' && qrCodeSettings && selectedOption !== qrCodeSettings.feedbackOption) {
            updateQRCodeSettings({colour: qrCodeSettings.colour, feedbackOption: selectedOption})
            if (selectedOption !== 'internal' && activeReviewSiteCount === 0 && qrReviewSites.length === 1) {
                toggleReviewSiteIsActive({reviewSite: {...qrReviewSites[0], isActive: true}, type: "qr-code"});
            }
        }
    }, [selectedOption]);


    const getReviewSiteName = (id: number | undefined): string => {
        // TODO: Change this to use the reviewSiteOptions returned from the API
        if (id === 1) return "Survey Form";
        if (id === 2) return "Google";
        if (id === 3) return "Custom URL";
        if (id === 4) return "Facebook";
        if (id === 6) return "Trustpilot";
        if (id === 7) return "YELP";
        return "";
    };

    useEffect(() => {
        if (selectedReviewSite !== undefined) {
            setReviewSiteModalOpen(true);
        }
    }, [selectedReviewSite])

    // TOGGLE REVIEW SITE ACTIVE
    const handleToggleReviewSiteIsActive = (
        event: React.ChangeEvent<HTMLInputElement>,
        site: ReviewSiteModel
    ) => {
        const numberActive = qrReviewSites.filter((reviewSite) => site !== reviewSite && reviewSite.isActive).length;
        if (numberActive >= 1 && selectedOption !== 'internal') {
            const model = {
                ...site,
                isActive: event.target.checked,
            };
            toggleReviewSiteIsActive({reviewSite: model, type: "qr-code"});
        }
    };
    const [selectedPlaceConfirmed, setSelectedPlaceConfirmed] = useState(false);
    const activeReviewSites = qrReviewSites.filter((reviewSite) => reviewSite.isActive);

    useEffect(() => {
        getQRCodeSettings()
    }, [])

    return feedbackOptionSaved ? (<div style={{position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%"}}>
        <Loading message={"SAVING..."}/></div>) :
        (<>
            <SectionComponent marginTop="30px">Step 1 - Feedback Options</SectionComponent>
            <FeedbackOption
                name="ExternalReviewForm"
                isActive={true}
                isSelected={true}
                onClick={() => {
                    setSelectedOption('external')
                }}
                tooltipText={"The External Review Form are forms that belong to external review or survey companies. With this service you can choose to redirect positive feedback to your GoogleMyBusiness review form or a Custom URL. This will result in more 4/5 star reviews, and improving your average Rating."}
            />
            <SectionComponent marginTop="30px">Step 2 - Review Sites</SectionComponent>
            <Box className="content-block table">
                <Box className={classes.tableHeader}>
                    <Typography>{`You can choose to redirect positive Customer Feedback to your review site, increasing the number of company Reviews, and improving your average Rating.`}</Typography>
                    <Button
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<Icon src={PlusIcon}/>}
                        onClick={() => setReviewSiteModalOpen(true)}
                    >
                        Add Review Site
                    </Button>
                </Box>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        size="small"
                        stickyHeader
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} align="left">
                                    Review Site
                                </TableCell>
                                <TableCell className={classes.cell} align="left">
                                    Site Name
                                </TableCell>
                                <TableCell className={classes.cell} align="left">
                                    Rating
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    Action
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    Active
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    Allocation
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {qrReviewSites?.length > 0 ? (
                                qrReviewSites.map((site) => {
                                        const activeSites = qrReviewSites.filter((reviewSite) => reviewSite.isActive);
                                        let buttonsDisabled = false;
                                        if (activeSites.length === 1 && activeSites.includes(site)) {
                                            buttonsDisabled = true;
                                        }

                                        return (
                                            <TableRow key={site.id}>
                                                <TableCell
                                                    component="td"
                                                    scope="row"
                                                    className={classes.cell}
                                                    align="left"
                                                >
                                                    <div>
                                                        {getReviewSiteName(+site.reviewSiteId)}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.cell} align="left">
                                                    {site.name}
                                                </TableCell>
                                                <TableCell className={classes.cell} align="left">
                                                    {site.rating ? `${site.rating} Average` : 0},{" "}
                                                    {site.ratingsTotal ? `${site.ratingsTotal} Reviews` : 0}
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={buttonsDisabled}
                                                        endIcon={<Icon src={editBtnIcon}/>}
                                                        onClick={() => {
                                                            setSelectedReviewSite(site)
                                                            setModalAction('edit');
                                                        }}
                                                    >
                                                        Edit Connection
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={buttonsDisabled && qrReviewSites.length > 1}
                                                        className={classes.removeButton}
                                                        onClick={() => {
                                                            setSelectedReviewSite(site)
                                                            setModalAction('remove');
                                                        }}
                                                    >
                                                        Remove Connection
                                                    </Button>
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Switch
                                                        color="secondary"
                                                        size="small"
                                                        checked={site.isActive}
                                                        disabled={
                                                            qrReviewSites === undefined || qrReviewSites.length <= 0
                                                        }
                                                        onChange={(e) =>
                                                            handleToggleReviewSiteIsActive(e, site)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    {(site.isActive && site.allocationPercentage) ? site.allocationPercentage : 0}%
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )
                            ) : (
                                <TableRow>
                                    <TableCell
                                        component="td"
                                        scope="row"
                                        className={classes.cell}
                                        align="left"
                                    >
                                        None
                                    </TableCell>
                                    <TableCell> - </TableCell>
                                    <TableCell>(0, 0)</TableCell>
                                    <TableCell className={classes.cell}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disabled
                                            endIcon={<Icon src={editBtnIcon}/>}
                                        >
                                            Edit Connection
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disabled
                                        >
                                            Remove Connection
                                        </Button>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Switch color="primary" size="small" disabled/>
                                    </TableCell>
                                    <TableCell>0%</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {qrReviewSites.length > 0 && activeReviewSites.length > 0 && (
                <>
                    <SectionComponent marginTop="30px" width="330px">Step 3 - External Review Options</SectionComponent>
                    <Box display={"flex"} flexDirection={"row"} gridGap={"1rem"}>
                        <FeedbackOption name={"ReviewSites"} isActive={true} isSelected={selectedOption !== 'funnel'}
                                        onClick={() => {
                                            setFeedbackOptionSavedState(true)
                                            setSelectedOption('external')

                                        }}
                                        tooltipText={"Positive feedback directs users straight to the review site(s) added in the \"External Review Form\" setup."}
                                        isDisabled={qrReviewSites.length === 0}
                        />
                        <FeedbackOption name={"ReviewFunnel"} isActive={true} isSelected={selectedOption === 'funnel'}
                                        onClick={() => {
                                            setFeedbackOptionSavedState(true)
                                            setSelectedOption('funnel')
                                        }}
                                        isDisabled={qrReviewSites.length === 0}
                                        tooltipText={"Positive feedback directs users to a confirmation page. Ratings of 4-stars or higher are redirected to your review profile. (Ideal for prioritizing high ratings when receiving a high volume of reviews)."}
                        />
                    </Box>
                    <Button className={classes.previewBtn}
                            href={`${appSettings.clientApp.baseAddress}/ext/r/${organization?.guid}` ?? '#'}
                            target="_blank">Preview Click</Button>
                </>)}

            <ReviewSiteModal
                open={reviewSiteModalOpen}
                onClose={() => {
                    setReviewSiteModalOpen(false);
                    setSelectedReviewSite(undefined);
                    setModalAction("");
                }}
                action={modalAction}
                selectedReviewSite={selectedReviewSite}
                getReviewSiteName={getReviewSiteName}
                reviewSiteOptions={reviewSiteOptions.filter(option => {
                    return !qrReviewSites.some((site: ReviewSiteModel) => site.reviewSiteId !== 3 && site.reviewSiteId === option.id)
                })}
                organization={organization}
                addReviewSite={(reviewSite, type) => addReviewSite({reviewSite, type})}
                deleteReviewSite={(id) => {
                    deleteReviewSite({id, type: "qr-code"});
                }}
                toggleSurveyFormIsActive={toggleSurveyFormIsActive}
                type={"qr-code"}
            />
            <SnackbarComponent
                isOpen={selectedPlaceConfirmed}
                msg="INTEGRATION COMPLETE"
                severity="success"
                handleClose={() => props.setAlertNotification(false)}
            />
        </>)
}


const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        profile: sharedSelectors.profileSelector(state),
        qrReviewSites: setupSelectors.qrReviewSiteSelector(state),
        reviewSiteOptions: setupSelectors.reviewSiteOptionsSelector(state),
        qrCodeSettings: setupSelectors.qrCodeSettingsSelector(state),
        feedbackOptionSaved: setupSelectors.feedbackOptionSavedSelector(state)
    };
};

const mapDispatchToProps = {
    addReviewSite,
    deleteReviewSite,
    getReviewSites,
    getReviewSiteOptions,
    getQRCodeSettings,
    setAlertNotification,
    toggleReviewSiteIsActive,
    toggleSurveyFormIsActive,
    updateQRCodeSettings,
    setFeedbackOptionSavedState
};

const QRCodeFeedback = connect(mapStateToProps, mapDispatchToProps)(QRCodeFeedbackPage);

export default QRCodeFeedback;
