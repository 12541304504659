import {Box, Typography} from "@material-ui/core";
import Loading from "../../shared/components/Loading";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {
    getQRCodeRedirectLink,
} from "../../SetupPage/setupActions";
import {connect} from "react-redux";
import {ApplicationState} from "../../ApplicationState";
import * as setupSelectors from "../../SetupPage/setupSelectors";

type QRCodeRedirectProps = {
    getQRCodeRedirectLink: typeof getQRCodeRedirectLink;
    qrCodeRedirectLink: string;
}

interface RouteParams {
    guid: string;
}

const QRCodeRedirect = (props: QRCodeRedirectProps) => {
    const {getQRCodeRedirectLink, qrCodeRedirectLink} = props;
    const {guid} = useParams<RouteParams>();

    useEffect(() => {
        getQRCodeRedirectLink(guid)
    }, []);

    useEffect(() => {
        if (qrCodeRedirectLink) {
            window.location.href = qrCodeRedirectLink;
        }
    }, [qrCodeRedirectLink])

    return (
        <Box className='login-container-wrapper'>
            <Box className='login-container'>
                <Box className='login-box' justifyContent='space-around'>
                    <Box className='login-box-item'>
                        <Box className='sign-box'>
                            <Typography className="title" variant='h4' style={{fontWeight: "bold"}}>Redirecting</Typography>
                            <Loading message={" "}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        qrCodeRedirectLink: setupSelectors.qrCodeRedirectLinkSelector(state),
    };
};

const mapDispatchToProps = {
    getQRCodeRedirectLink
};

const QRCodeRedirectPage = connect(mapStateToProps, mapDispatchToProps)(QRCodeRedirect);

export default QRCodeRedirectPage;