import {appSettings} from '../config/appSettings';
//need to separate into package
// import { get, postNoResult, post } from 'redux-auth';
import {fetchBase, get, post, putNoResult} from '../auth/authAPI';
import {Organization, ReturnResultData} from '../shared/SharedData';
import {AddCreditCard, CreditCard, InviteAdministratorRequest, UpdateBillingInfo} from '../AccountPage/AccountData';
import {
    AddAgent,
    AddReviewSite,
    AgentProfile,
    ImportUser,
    ReviewSite,
    Template,
    TextTemplate
} from '../SetupPage/SetupPageData';
import {Feedback} from '../Feedback/FeedbackData';
import {FeedbackModel, Filters} from '../Feedback/FeedbackModels';
import {FeedbackSummaryParams} from '../ReportingPage/ReportingData';
import {ReportingFilters} from '../ReportingPage/ReportingModels';
import {AddNotificationSettings, UpdateNotificationSettings} from '../SettingsPage/SettingsApiData';
import {ConfirmPaymentRequest, SubmitCouponCodeModel} from '../AccountPage/AccountModels';
import {
    QRCodeSettingsUpdateModel,
    SuggestionRequest,
    TemplateFeedbackOptionModel,
    TextTemplateUpdateModel
} from "../SetupPage/SetupModels";
import {AgencySettings, LogoUpload, SurveySetting} from "../SettingsPage/SettingsModels";

// declare const appSettings: any;

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;
const apiUrlRoot = appSettings.apisMetadata.find(x => x.id === 'rms')!.baseAddress;

export const verifyIdToken = (provider: string) => {
    fetch(`${apiUrlRoot}/api/auth-from-code/${provider}}`,);
};

// export const getTemplate = () => {
//     return get(apiName, apiUrlRoot + `/${apiName}` + '/userprofile');
// };

function getReturnResult<TData>(thisData: TData): ReturnResultData<TData> {
    return {
        statusCode: 'OK',
        message: '',
        data: thisData,
        errors: [],
    };
}

export const getWhiteLabelProfile = (domain: string) => {
    return fetch(apiUrlRoot + `/${apiName}/white-label?domain=${domain}`).then(response => response.json());
}


export const getUserProfile = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/profile`);
};


export const getOrganization = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/organization`);

}

export const switchOrganization = (organizationId: number) => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/switch-organization/${organizationId}`);
}


export const addAgent = (data: AddAgent) => {

    const newAgent: { [key: string]: any } = {
        login: data.login,
        fullName: data.fullName,
        isActive: data.isActive,
    }

    const newAgentData = new FormData();
    for (const i in newAgent) {
        newAgentData.append(i, newAgent[i as keyof typeof newAgent])
    }

    return post(apiName, apiUrlRoot + `/${apiName}/user/agent/add`, newAgentData);
};


export const getAgents = (organizationId: number | undefined) => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/white-label-agents/${organizationId}`);
}


export const updateAgent = (data: AgentProfile) => {
    const agent = {
        id: data.id,
        fullName: data.fullName,
        login: data.login,
        isActive: data.isActive ? 1 : 0,
    }

    return putNoResult(apiName, apiUrlRoot + `/${apiName}/user/agent/${data.id}`, agent);
};


export const toggleFeedbackIsArchived = (data: any) => {
    const feedback = {
        id: data.id,
        isArchived: data.isArchived ? 1 : 0,
    }

    return putNoResult(apiName, apiUrlRoot + `/${apiName}/feedback/customer-feedback/${data.id}`, feedback);
}


export const uploadCSV = (file: any): ImportUser[] => {
    const data = new FormData();
    data.append("file", file);
    // data.append('file', new Blob([file], { type: 'text/csv' }));

    const options = {
        body: data,
        headers: {
            // "Content-Type": "text/csv",
            // "mimeType": "multipart/form-data",
            // "type": "formData",
        }
    }

    return fetchBase("POST", apiUrlRoot + `/${apiName}/user/import/csv`, apiName, options);
};


export const importFromEmailClient = (client: string, code: string) => {
    // return post(apiName, apiUrlRoot + `/${apiName}` + `/user/import/${client}?code=${code}`, {}); // Differs from new API call
    return post(apiName, apiUrlRoot + `/${apiName}/user/agent/import/${client}?code=${code}`, {}); // Changed to match new API
};


export const getAgentsWithSnippetByOrg = (organizationId: number | undefined) => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/agents-with-snippet-by-org/${organizationId}`);
};


export const getAgentSnippet = (agentId: string) => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/agent-snippet/${agentId}`);
};


export const uploadLogo = (payload: LogoUpload) => {
    const data = new FormData();
    data.append("file", payload.file);
    // add the organisation id to the form data
    if (payload.organisationId) {
        data.append("organisationId", payload.organisationId.toString());
    }
    const options = {
        body: data,
    }

    return fetchBase("POST", apiUrlRoot + `/${apiName}/user/logo/update`, apiName, options);
};

export const uploadFavicon = (payload: LogoUpload) => {
    const data = new FormData();
    data.append("file", payload.file);
    // add the organisation id to the form data
    if (payload.organisationId) {
        data.append("organisationId", payload.organisationId.toString());
    }
    const options = {
        body: data,
    }

    return fetchBase("POST", apiUrlRoot + `/${apiName}/white-label/favicon/update`, apiName, options);
};

export const uploadAgencyLogo = (payload: LogoUpload) => {
    const data = new FormData();
    data.append("file", payload.file);
    // add the organisation id to the form data
    if (payload.organisationId) {
        data.append("organisationId", payload.organisationId.toString());
    }
    const options = {
        body: data,
    }

    return fetchBase("POST", apiUrlRoot + `/${apiName}/white-label/logo/update`, apiName, options);
}

export const getUsers = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/administrators`);
}


export const deleteUser = (id: number) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/user/administrator/${id}`, {});
};


export const inviteUser = (data: InviteAdministratorRequest) => {

    const invitedUser: { [key: string]: any } = {
        fullName: data.fullName,
        email: data.email,
    }

    const invitedUserData = new FormData();
    for (const i in invitedUser) {
        invitedUserData.append(i, invitedUser[i as keyof typeof invitedUser])
    }

    return post(apiName, apiUrlRoot + `/${apiName}/user/invite-user`, invitedUserData);
};


export const emailSurveyToAgent = (agentId: number) => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/email-survey-to/${agentId}`);
};


export const getLanguages = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/languages`);
}


export const getFontSizeTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/font-sizes`);
}

export const getTitleSpacingTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/title-spacing`);

}

export const getIconSizeTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/icon-size`);
}

export const getIconSpacingTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/icon-spacing`);
}

export const getFontFamilyTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/font-families`);
}


export const getIconSets = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/icon-sets`);
}

export const getTextSets = (languageId: number | undefined) => {
    if (languageId) {
        return get(apiName, apiUrlRoot + `/${apiName}/survey-template/text-sets?languageId=${languageId}`)
    }

    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/text-sets`)
}

export const getColorPalette = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/palette`);
}


export const getTemplateActionTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/titles`);
}


export const getSurveyTypes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/survey-types`);
}


export const getTemplate = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-template/current`);
}

export const getTextTemplate = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/survey-set/current`)
}

export const updateTemplate = (template: Template) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/survey-template/${template.id}`, template);
}

export const updateTextTemplate = (textTemplateUpdated: TextTemplateUpdateModel) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/survey-set`, textTemplateUpdated)
}

export const updateTemplateFeedbackOption = (template: TemplateFeedbackOptionModel) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/survey-template/${template.id}/feedback-option`, template);
}

export const getOrganizationPlan = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/subscription/current`);
}


export const getSubscriptionPlans = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/subscription/available`);
}


export const updateSubscription = (id: number) => {
    return get(apiName, apiUrlRoot + `/${apiName}/subscription/update/${id}`);
}


export const deactivateSubscription = (orgId?: number) => {

    const organizationId: { [key: string]: any } = new FormData();
    organizationId.append("orgId", orgId)

    return post(apiName, apiUrlRoot + `/${apiName}/subscription/deactivate`, organizationId);
}


export const getCreditCardInfo = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/billing-information/credit-cards`);
};


export const addCreditCard = (data: AddCreditCard) => {

    const newCreditCard: { [key: string]: any } = {
        paymentMethodId: data.paymentMethodId,
        isDefault: data.isDefault,
        customerId: data.customerId,
    }

    const newCreditCardData = new FormData();
    for (const i in newCreditCard) {
        newCreditCardData.append(i, newCreditCard[i as keyof typeof newCreditCard])
    }

    return post(apiName, apiUrlRoot + `/${apiName}/billing-information/credit-card/add`, newCreditCardData);
};


export const updateCreditCard = (data: CreditCard) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/billing-information/credit-card/${data.id}`, data);
};


export const updateBillingInfo = (data: UpdateBillingInfo) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/billing-information/edit`, data);
};

// hardcoded
export const getInvoice = (id: number) => {
    return get(apiName, apiUrlRoot + `/${apiName}/billing-information/invoice/${id}`);
};


export const getInvoices = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/billing-information/invoices`);
};


export const setDefaultCreditCard = (cardId: number) => {
    return get(apiName, apiUrlRoot + `/${apiName}/billing-information/credit-card/${cardId}/default`);
};


export const createStripeSetupIntent = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/billing/create-setup-intent`);
};


export const pay = (request: ConfirmPaymentRequest) => {
    return get(apiName, apiUrlRoot + `/${apiName}/billing/pay`);
};


export const getFeedback = (id: number) => {
    return get(apiName, apiUrlRoot + `/${apiName}/feedback/${id}`);
};


export const getSuggestions = (data: SuggestionRequest) => {
    return get(apiName, apiUrlRoot + `/${apiName}/review/get-suggestions/${data.input}/${data.token}`);
}


// export const getSurveyAnswerFormDetails = (params: GetFormOptionsParams) => {
//     const resourceUrl = apiUrlRoot + `/${apiName}` + `/feedback/formoptions?organizationid=${params.organizationId}&agentid=${params.agentId}&answerid=${params.surveyAnswerId}`;

//     return get(apiName, resourceUrl);
// };

// export const leaveFeedback = (feedback: Feedback) => {
//     const resourceUrl = apiUrlRoot + `/${apiName}` + `/feedback/add`;

//     return post(apiName, resourceUrl, feedback);
// };


export const leaveFeedback = (feedback: Feedback, authToken: string) => {

    const feedbackLeft: { [key: string]: any } = {
        id: feedback.id,
        createdDate: feedback.createdDate,
        organizationId: feedback.organizationId,
        agentId: feedback.agentId,
        agentName: feedback.agentName,
        customerName: feedback.customerName,
        message: feedback.message,
        iconPath: feedback.iconPath,
        iconType: feedback.iconType,
        customerSatisfactionType: feedback.customerSatisfactionType,
        surveyAnswerId: feedback.surveyAnswerId,
        surveyId: feedback.surveyId,
        surveyAnswerTemplateId: feedback.surveyAnswerTemplateId,
        rate: feedback.rate,
        backgroundColor: feedback.backgroundColor,
        color: feedback.color,
        organisationFeedback: feedback.organisationFeedback,
    }

    const feedbackLeftData = new FormData();
    for (const i in feedbackLeft) {
        feedbackLeftData.append(i, feedbackLeft[i as keyof typeof feedbackLeft]);
    }

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: feedbackLeftData,
    }

    return fetch(apiUrlRoot + `/${apiName}/feedback/add`, options);
};


export const getAllFeedback = (params: Filters) => {
    const filterKeys = Object.keys(params);
    let resourceUrl = apiUrlRoot + `/${apiName}/feedback`;

    if (filterKeys.some(key => params[key])) {
        resourceUrl += '?';

        filterKeys.forEach((key, index) => {
            if (params[key] && index === 0) resourceUrl += `${key}=${params[key]}`
            else if (params[key]) resourceUrl += `&${key}=${params[key]}`
        })
    }

    return get(apiName, resourceUrl);
};


export const getReportSummary = (filters: ReportingFilters, params?: FeedbackSummaryParams) => {
    let resourceUrl = apiUrlRoot + `/${apiName}/feedback/report`;

    // @ts-ignore
    const timeParams = new URLSearchParams(params)
    const filterParams = new URLSearchParams(filters)

    if (params) resourceUrl += `?${timeParams.toString()}&${filterParams.toString()}`
    if (!params) resourceUrl += `?${filterParams.toString()}`

    return get(apiName, resourceUrl);
};


export const getGroupedFeedbackSummary = (params: FeedbackSummaryParams, filters: ReportingFilters) => {
    let resourceUrl = apiUrlRoot + `/${apiName}/feedback/report-by-period?periodType=${params.periodType}&period=${params.period}`;

    const filtersObjKeys = Object.keys(filters);
    filtersObjKeys.forEach(k => {
        const value = filters[k];
        if (filters[k]) resourceUrl += `&${k}=${filters[k]}`;
    });

    return get(apiName, resourceUrl);
};


export const getAgentFilterForReport = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/feedback/agent-filter`);
};


export const getNotificationSettings = (surveyId?: number) => {
    let resourceUrl = apiUrlRoot + `/${apiName}/notification/settings`;

    if (surveyId) resourceUrl += `?surveyId=${surveyId}`;

    return get(apiName, resourceUrl);
};


export const addNotificationSettings = (notificationSetting: AddNotificationSettings) => {
    const addNotificationFormData = new FormData();
    Object.keys(notificationSetting).forEach((dataKey, i) => {
        addNotificationFormData.append(dataKey, String(notificationSetting[dataKey as keyof AddNotificationSettings]));
    });
    return post(apiName, apiUrlRoot + `/${apiName}/notification/settings`, addNotificationFormData);
};

export const editNotificationSettings = (data: UpdateNotificationSettings) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/notification/settings`, data);
};


export const loadCouponCodes = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/coupon-codes`);
};


export const submitCouponCode = (data: SubmitCouponCodeModel) => {

    const submittedCouponCode: { [key: string]: any } = {
        couponCode: data.couponCode,
        organizationId: data.organizationId,
    }

    const submittedCouponCodeData = new FormData();
    for (const i in submittedCouponCode) {
        submittedCouponCodeData.append(i, submittedCouponCode[i as keyof typeof submittedCouponCode])
    }

    return post(apiName, apiUrlRoot + `/${apiName}/user/add-coupon-code`, submittedCouponCodeData);
};


export const getAllReviews = (params: Filters) => {
    const filterKeys = Object.keys(params);
    let resourceUrl = apiUrlRoot + `/${apiName}/reviews`;

    if (filterKeys.some(key => params[key])) {
        resourceUrl += '?';

        filterKeys.forEach((key, index) => {
            if (params[key] && index === 0) resourceUrl += `${key}=${params[key]}`
            else if (params[key]) resourceUrl += `&${key}=${params[key]}`
        })
    }

    return get(apiName, resourceUrl);
};


export const toggleReviewSiteIsActive = (data: ReviewSite) => {
    const reviewSite = {
        id: data.id,
        isActive: data.isActive ? 1 : 0,
    }

    return putNoResult(apiName, apiUrlRoot + `/${apiName}/review/review-site`, reviewSite);
}


export const toggleSurveyFormIsActive = (data: Organization) => {
    const organization = {
        id: data.id,
        isActive: data.surveyFormIsActive ? 1 : 0,
    }

    return putNoResult(apiName, apiUrlRoot + `/${apiName}/review/survey-form`, organization);
}


export const addReviewSite = (data: AddReviewSite) => {
    const newReviewSite: { [key: string]: any } = {
        reviewSiteId: data.reviewSiteId,
        identifier: data.identifier,
        name: data.name,
        rating: data.rating,
        url: data.url,
        ratingsTotal: data.ratingsTotal,
        isActive: data.isActive ? 1 : 0,
    }

    const newReviewSiteData = new FormData();
    for (const i in newReviewSite) {
        newReviewSiteData.append(i, newReviewSite[i as keyof typeof newReviewSite])
    }

    return post(apiName, apiUrlRoot + `/${apiName}/review/add-review-site`, newReviewSiteData);
};


export const deleteReviewSite = (id: number) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/review/delete-review-data/${id}`, null);
};


export const getReviewSites = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/review/get-review-sites`);
}

export const getQRReviewSites = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/review/get-review-sites?type=1`);
}

export const addQRReviewSite = (data: AddReviewSite) => {
    const newReviewSite: { [key: string]: any } = {
        reviewSiteId: data.reviewSiteId,
        identifier: data.identifier,
        name: data.name,
        rating: data.rating,
        url: data.url,
        ratingsTotal: data.ratingsTotal,
        isActive: data.isActive ? 1 : 0,
    }

    const newReviewSiteData = new FormData();
    for (const i in newReviewSite) {
        newReviewSiteData.append(i, newReviewSite[i as keyof typeof newReviewSite])
    }

    return post(apiName, apiUrlRoot + `/${apiName}/review/add-review-site?type=1`, newReviewSiteData);
};


export const getReviewSiteOptions = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/review/review-site-options`);
}

export const updateAgencySettings = (data: AgencySettings) => {
    const settings: { [key: string]: any } = {
        organisationId: data.organisationId,
        termsService: data.termsService ?? "",
        privacyPolicy: data.privacyPolicy ?? "",
        cookiePolicy: data.cookiePolicy ?? "",
        fromReplyEmail: data.fromReplyEmail ?? "",
        pageTabTitle: data.pageTabTitle ?? "",
    }

    const settingsFormData = new FormData();
    for (const i in settings) {
        settingsFormData.append(i, settings[i as keyof typeof settings]);
    }
    return post(apiName, apiUrlRoot + `/${apiName}/white-label/update`, settingsFormData);
}

export const verifyReCaptcha = async (token: string, authToken: string) => {
    const verifyFormdata = new FormData();
    verifyFormdata.append('token', token);

    return await fetch(`${apiUrlRoot}/${apiName}/auth/verify-recaptcha`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: verifyFormdata,
    }).then(response => response.json());

}

export const getSubscriptionTrialDays = async () => {
    return await fetch(apiUrlRoot + `/${apiName}/subscription/trial-days`)
        .then(response => response.json());
}

export const getSurveySettings = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/setting/survey`);
}

export const toggleAnonymousSetting = (data: SurveySetting) => {
    const formData = new FormData();
    formData.append("isActive", String(data.anonymousActive));
    return post(apiName, apiUrlRoot + `/${apiName}/setting/survey/toggle-anonymous`, formData);
}

export const unsubscribeUserFromEmail = (token: string, authToken: string) => {
    const url = apiUrlRoot + `/${apiName}/notification/unsubscribe`
    const tokenData = new FormData()
    tokenData.append("key", token)

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: tokenData,
    }

    return fetch(url, options);
}

export const updateSurveySetType = (typeId: number) => {
    return putNoResult(apiName, apiUrlRoot + `/${apiName}/survey-template/type/${typeId}`, null)
}

export const getPreviewLink = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/user/preview-link`);
}

export const getQRCodeSettings = () => {
    return get(apiName, apiUrlRoot + `/${apiName}/qr-code`);
}

export const updateQRCodeSettings = (data: QRCodeSettingsUpdateModel) => {

    return putNoResult(apiName, apiUrlRoot + `/${apiName}/qr-code`, data)
}

export const updateQRCodeLogo = (qrCodeLogo: File) => {
    const data = new FormData()
    data.append("logo", qrCodeLogo)

    const options = {
        body: data,
    }

    return fetchBase("POST", apiUrlRoot + `/${apiName}/qr-code/logo/update`, apiName, options);
}

export const getQRCodeRedirectLink = (guid: string) => {
    return get(apiName, apiUrlRoot + `/${apiName}/review/submission-redirect?organisationId=${guid}&type=1`)
}