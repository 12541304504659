import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import { connect } from "react-redux";

import * as s from "../sharedSelectors";
import { UserProfileModel } from "../SharedModels";
import { ApplicationState } from "../../ApplicationState";

import { redirect } from "../router/routerActions";
import { switchNavMenu } from "../sharedActions";
import { appSettings } from "../../config/appSettings";

import SettingsIcon from "../../svg/settings";
import TutorialIcon from "../../svg/TutorialIcon";
import ExternalLink from "./ExternalLink";
import FeedbackIcon from "../../svg/feedback";
import ReportingIcon from "../../svg/reporting";
import ReviewsIcon from "../../svg/reviews";
import SetupIcon from "../../svg/setup";

const appUrl = appSettings.clientApp.appUrl;

type NavTabsProps = {
  navTabIsSelected: boolean;
  userProfile: UserProfileModel | undefined;
  isDisabledService: boolean;

  redirect: typeof redirect;
  switchNavMenu: typeof switchNavMenu;
};

// define link tab props
type LinkTabProps = {
  redirectTo: string;
};

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
    index,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#3B76EF",
    width: "212px",
    minWidth: "212px",
  },
  tab: {
    height: "100px",
  },
  selectedTab: {
    backgroundColor: "#F1F1F1",
    color: "#3B76EF",
  },
  settingsTab: {
    marginTop: "283px",
  },
  tutorialButton: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

function NavTabs(props: RouteComponentProps & NavTabsProps) {
  const classes = useStyles();
  const {
    navTabIsSelected,
    userProfile,
    location,
    isDisabledService,
    switchNavMenu,
  } = props;
  const [selectedTab, setSelectedTab] = useState<number | boolean>(0);
  const [redirect, setRedirect] = useState('');
  const orgId = userProfile ? userProfile.organization.id : 0;

  useEffect(() => {
    // reset the redirect to avoid a loop
    if (redirect.length > 0) {
      setRedirect('');
    }
  }, [redirect]);

  useEffect(() => {
    if (!navTabIsSelected) {
      setSelectedTab(false);
    } else if (location.pathname) {
      const tabIndex = getTabIndex(location.pathname);
      setSelectedTab(tabIndex);
    }
  }, [navTabIsSelected]);

  const getTabIndex = (url: string) => {
    if (/setup/.test(url)) return 0;
    if (/customerfeedback/.test(url)) return 1;
    if (/reviews/.test(url)) return 2;
    if (/reporting/.test(url)) return 3;
    if (/settings/.test(url)) return 4;

    return 0;
  };

  const handleChange = (event: any, tabIndex: any) => {
    setSelectedTab(tabIndex);
    switchNavMenu(true);
  };

  const LinkTab = (props: any) => {
    return (
      <Tab
        component={Box}
        className={`${classes.tab} ${
          props.index === selectedTab ? classes.selectedTab : ""
        } ${props.styleprops ? props.styleprops : ""}`}
        onClick={(event: any) => {
          setRedirect(props.redirectto);
        }}
        disabled={isDisabledService}
        {...props}
      />
    );
  };

  useEffect(() => {
    if (/setup/.test(location.pathname)) {
      setSelectedTab(0)
      switchNavMenu(true)
    }
  }, [location.pathname]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab
            label={
              <>
                <SetupIcon isSelected={selectedTab === 0} />
                Setup
              </>
            }
            redirectto={`/org${orgId}/setup/customize`}
            {...a11yProps(0)}
          />
          <LinkTab
            label={
              <>
                <FeedbackIcon isSelected={selectedTab === 1} />
                Customer Feedback
              </>
            }
            redirectto={`/org${orgId}/customerfeedback`}
            {...a11yProps(1)}
          />
          <LinkTab
            label={
              <>
                <ReviewsIcon isSelected={selectedTab === 2} />
                Customer Reviews
              </>
            }
            redirectto={`/org${orgId}/reviews`}
            {...a11yProps(2)}
          />
          <LinkTab
            label={
              <>
                <ReportingIcon isSelected={selectedTab === 3} />
                Reporting
              </>
            }
            redirectto={`/org${orgId}/reporting`}
            {...a11yProps(3)}
          />
          <LinkTab
            label={
              <>
                <SettingsIcon isSelected={selectedTab === 4} />
                Settings
              </>
            }
            redirectto={`/org${orgId}/settings`}
            styleprops={classes.settingsTab}
            {...a11yProps(4)}
          />
          <div className={classes.tutorialButton}>
            <ExternalLink href="https://www.youtube.com/@CustomerFeedback-qb9lm/playlists">
              <TutorialIcon />
            </ExternalLink>
          </div>
        </Tabs>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  return {
    navTabIsSelected: s.navTabSelector(state),
    userProfile: s.profileSelector(state),
    isDisabledService: s.isDisabledServiceSelector(state),
    // errors: selectors.todoListsErrorSelector(errors),
    // ownProps
  };
};

const mapDispatchToProps = { redirect, switchNavMenu };

const NavTabsContainer = connect(mapStateToProps, mapDispatchToProps)(NavTabs);

export default withRouter(NavTabsContainer);
