import React, { useEffect, useState } from "react";
import {
  Box,
  Button, Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  addReviewSite,
  deleteReviewSite,
  changeSaveTemplateFlag,
  getSuggestions,
  updateTemplateFeedbackOption,
  getTemplate,
  toggleReviewSiteIsActive,
  toggleSurveyFormIsActive,
  getReviewSites,
  getReviewSiteOptions,
  setAlertNotification,
  setReviewSiteAllocationPercentage,
  getPreviewLink,
  getAgentsWithSnippetByOrg
} from "../setupActions";

import { UserProfileModel } from "../../shared/SharedModels";
import { makeStyles } from "@material-ui/core/styles";
import {
  AgentWithSnippetModel,
  AutocompleteSuggestion,
  ReviewSiteModel,
  ReviewSiteOptionModel, TemplateFeedbackOptionModel, TemplateModel,
} from "../SetupModels";

import editBtnIcon from "../../svg/editbtn.svg";
import Icon from "../../shared/components/Icon";
import PlusIcon from "../../svg/plusbtn.svg";
import SectionComponent from "../../shared/components/SectionComponent";
import "../setupPage.scss";
import { appSettings } from "../../config/appSettings";
import { ReviewsModel } from "../../ReviewsPage/reviewsModels";
import { getAllReviews } from "../../ReviewsPage/reviewsActions";
import SnackbarComponent from "../../shared/components/Snackbar";
import FeedbackOption from "./FeedbackOption";
import ReviewSiteModal from "./ReviewSiteModal";
import {useStepperDispatch} from "./SetupStepper";

type ReviewsPageProps = {
  profile: UserProfileModel | undefined;
  reviews: ReviewsModel[];
  reviewSites: ReviewSiteModel[];
  currentTemplate: TemplateModel | undefined;
  reviewSiteOptions: ReviewSiteOptionModel[];
  suggestions: AutocompleteSuggestion[];
  isSaved: boolean;
  getSuggestions: typeof getSuggestions;
  getReviewSites: typeof getReviewSites;
  updateTemplateFeedbackOption: typeof updateTemplateFeedbackOption;
  getTemplate: typeof getTemplate;
  getReviewSiteOptions: typeof getReviewSiteOptions;
  changeSaveTemplateFlag: typeof changeSaveTemplateFlag;
  toggleReviewSiteIsActive: typeof toggleReviewSiteIsActive;
  toggleSurveyFormIsActive: typeof toggleSurveyFormIsActive;
  addReviewSite: typeof addReviewSite;
  deleteReviewSite: typeof deleteReviewSite;
  getAllReviews: typeof getAllReviews;
  setAlertNotification: typeof setAlertNotification;
  setReviewSiteAllocationPercentage: typeof setReviewSiteAllocationPercentage;
  getPreviewLink: typeof getPreviewLink;
  previewLinkUrl: string;
  agents: AgentWithSnippetModel[];
  getAgentsWithSnippetByOrg: typeof getAgentsWithSnippetByOrg,
};

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: "250px",
  },
  table: {
    minWidth: 650,
    borderLeft: "2px solid #fff",
    borderRight: "2px solid #fff",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  tableHeader: {
    height: "45px",
    display: "flex",
    padding: "10px",
    alignItems: "center",
    backgroundColor: "#fff",
    justifyContent: "space-between",
  },
  cell: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  warningButton: {
    color: "white",
    backgroundColor: "red",
  },
  removeButton: {
    color: "#F43B3B",
    borderColor: "#F43B3B",
    marginLeft: "0.5rem",
    '&:hover': {
      color: "#fa7a7a",
      borderColor: "#fa7a7a",
      backgroundColor: "transparent",
    }
  },
  addButton: {
    whiteSpace: "nowrap",
  },
  previewBtnDiv: {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "center",
  },
  previewBtn: {
    fontWeight: "bold",
    color: "white",
    backgroundColor: '#F7BA00',
    borderRadius: "5px",
    marginTop: "32px",
    padding: "4px 32px",
    "&:hover": {
      backgroundColor: '#a98001',
      boxShadow: 'none'
    }
  },
});

const ReviewsPage: React.FC<ReviewsPageProps> = (props) => {
  const dispatch = useStepperDispatch();
  const classes = useStyles();
  const {
    reviewSites,
    currentTemplate,
    reviewSiteOptions,
    profile,
    toggleReviewSiteIsActive,
    toggleSurveyFormIsActive,
    getTemplate,
    addReviewSite,
    deleteReviewSite,
    getReviewSites,
    getReviewSiteOptions,
    getAllReviews,
    setReviewSiteAllocationPercentage,
    previewLinkUrl,
    getPreviewLink,
    getAgentsWithSnippetByOrg,
    agents
  } = props;

  const organization = profile?.organization;
  const organizationId = profile?.organization.id;
  const defaultUserId = profile?.organization.defaultUserId;
  const surveyFormIsActive = profile?.organization.surveyFormIsActive;

  const [reviewSiteModalOpen, setReviewSiteModalOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [activeReviewSiteCount, setActiveReviewSiteCount] = useState<number>(0);
  const [selectedReviewSite, setSelectedReviewSite] = useState<ReviewSiteModel | undefined>(undefined);
  const [modalAction, setModalAction] = useState<"" | "edit" | "remove">("");

  const initialFilters = {
    rating: "",
    reviewSiteId: "",
    dataRange: "",
    page: "1",
    searchString: "",
  };

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    let canGoForward = false;
    if (selectedOption === 'internal') {
      canGoForward = true;
    }
    if (reviewSites.length > 0 && selectedOption !== 'internal') {
      canGoForward = true;
    }
    dispatch({type: 'setCanGoForward', payload: canGoForward});
  }, [reviewSites, selectedOption]);

  useEffect(() => {
    getTemplate();
    getReviewSites("email-signature");
    getReviewSiteOptions();
    getAllReviews(filters);
  }, [organization]);

  useEffect(() => {
    if (currentTemplate) {
      setSelectedOption(currentTemplate.feedbackOption);
    }

  }, [currentTemplate]);

  useEffect(() => {
    if (selectedOption !== '' && currentTemplate && selectedOption !== currentTemplate.feedbackOption) {
      const model = {
        id: currentTemplate.id,
        feedbackOption: selectedOption,
      };

      props.updateTemplateFeedbackOption(model as TemplateFeedbackOptionModel);
      if (selectedOption !== 'internal' && activeReviewSiteCount === 0 && reviewSites.length === 1) {
        toggleReviewSiteIsActive({reviewSite: {...reviewSites[0], isActive: true}, type: "email-signature"});
      }
    }
  }, [selectedOption]);

  const getReviewSiteName = (id: number | undefined): string => {
     // TODO: Change this to use the reviewSiteOptions returned from the API
    if (id === 1) return "Survey Form";
    if (id === 2) return "Google";
    if (id === 3) return "Custom URL";
    if (id === 4) return "Facebook";
    if (id === 6) return "Trustpilot";
    if (id === 7) return "YELP";
    return "";
  };

  useEffect(() => {
    if (selectedReviewSite !== undefined) {
      setReviewSiteModalOpen(true);
    }
  }, [selectedReviewSite])

  // TOGGLE REVIEW SITE ACTIVE
  const handleToggleReviewSiteIsActive = (
      event: React.ChangeEvent<HTMLInputElement>,
      site: ReviewSiteModel
  ) => {
    const numberActive = reviewSites.filter((reviewSite) => site !== reviewSite && reviewSite.isActive).length;
    if (numberActive >= 1 && selectedOption !== 'internal') {
      const model = {
        ...site,
        isActive: event.target.checked,
      };

      toggleReviewSiteIsActive({reviewSite: model, type: "email-signature"});
    }
  };

  // CONFIRM CORRECT GOOGLE PLACE SELECTED
  const [selectedPlaceConfirmed, setSelectedPlaceConfirmed] = useState(false);

  const activeReviewSites = reviewSites.filter((reviewSite) => reviewSite.isActive);

  useEffect(() => {
    getPreviewLink()
    getAgentsWithSnippetByOrg()
  }, [])

  const getHrefLocation = () => {
    if (selectedOption === 'funnel') {
      return `${appSettings.reviewsApp.submitReviewUrl}/${organizationId}/${defaultUserId}/${activeReviewSites[0].reviewSiteId}`;
    }

    if (agents.length > 0) {
      const htmlSnippet = agents[0].snippet;

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlSnippet, 'text/html');

      const links = doc.querySelectorAll('a[href]');

      return links.length > 0 ? links[links.length - 1].getAttribute('href') : null;
    }
  }

  return (
      <>
        <SectionComponent marginTop="30px">Step 1 - Feedback Options</SectionComponent>
        <Box display={"flex"} flexDirection={"row"} gridGap={"1rem"}>
          <FeedbackOption
              name="InternalReviewForm"
              isActive={true}
              isSelected={selectedOption === 'internal'}
              onClick={() => {setSelectedOption('internal')}}
              tooltipText={"The Internal Review Form is the standard survey form that the end-user would fill out after clicking on the in-signature survey. Here is an example: https://bit.ly/4a2iwj4"}
          />
          <FeedbackOption
              name="ExternalReviewForm"
              isActive={true}
              isSelected={selectedOption === 'external' || selectedOption === 'funnel'}
              onClick={() => {setSelectedOption('external')}}
              tooltipText={"The External Review Form are forms that belong to external review or survey companies. With this service you can choose to redirect positive feedback to your GoogleMyBusiness review form or a Custom URL. This will result in more 4/5 star reviews, and improving your average Rating."}
          />
        </Box>
        {selectedOption === 'internal' && (<Button
            className={classes.previewBtn}
            href={previewLinkUrl}
            target="_blank">Preview Click</Button>)}
        {selectedOption !== 'internal' && (
            <>
              <SectionComponent marginTop="30px">Step 2 - Review Sites</SectionComponent>

              <Box className="content-block table">
                <Box className={classes.tableHeader}>
                  <Typography>{`You can choose to redirect positive Customer Feedback to your review site, increasing the number of company Reviews, and improving your average Rating.`}</Typography>
                  <Button
                      className={classes.addButton}
                      variant="contained"
                      color="primary"
                      size="small"
                      endIcon={<Icon src={PlusIcon} />}
                      onClick={() => setReviewSiteModalOpen(true)}
                  >
                    Add Review Site
                  </Button>
                </Box>
                <TableContainer className={classes.tableContainer}>
                  <Table
                      className={classes.table}
                      size="small"
                      stickyHeader
                      aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell} align="left">
                          Review Site
                        </TableCell>
                        <TableCell className={classes.cell} align="left">
                          Site Name
                        </TableCell>
                        <TableCell className={classes.cell} align="left">
                          Rating
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          Action
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          Active
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          Allocation
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reviewSites?.length > 0 ? (
                          reviewSites.map((site) => {
                                const activeSites = reviewSites.filter((reviewSite) => reviewSite.isActive);
                                let buttonsDisabled = false;
                                if (activeSites.length === 1 && activeSites.includes(site)) {
                                  buttonsDisabled = true;
                                }

                                return (
                                    <TableRow key={site.id}>
                                      <TableCell
                                          component="td"
                                          scope="row"
                                          className={classes.cell}
                                          align="left"
                                      >
                                      <div>
                                        {getReviewSiteName(+site.reviewSiteId)}
                                      </div>
                                      </TableCell>
                                      <TableCell className={classes.cell} align="left">
                                          {site.name}
                                      </TableCell>
                                      <TableCell className={classes.cell} align="left">
                                          {site.rating ? `${site.rating} Average` : 0},{" "}
                                          {site.ratingsTotal ? `${site.ratingsTotal} Reviews` : 0}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disabled={buttonsDisabled}
                                            endIcon={<Icon src={editBtnIcon}/>}
                                            onClick={() => {
                                              setSelectedReviewSite(site)
                                              setModalAction('edit');
                                            }}
                                        >
                                          Edit Connection
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disabled={buttonsDisabled && reviewSites.length > 1}
                                            className={classes.removeButton}
                                            onClick={() => {
                                              setSelectedReviewSite(site)
                                              setModalAction('remove');
                                            }}
                                        >
                                          Remove Connection
                                        </Button>
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        <Switch
                                            color="secondary"
                                            size="small"
                                            checked={site.isActive}
                                            disabled={
                                                reviewSites === undefined || reviewSites.length <= 0
                                            }
                                            onChange={(e) =>
                                                handleToggleReviewSiteIsActive(e, site)
                                            }
                                        />
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {(site.isActive && site.allocationPercentage) ? site.allocationPercentage : 0}%
                                      </TableCell>
                                    </TableRow>
                                );
                              }
                          )
                      ) : (
                          <TableRow>
                            <TableCell
                                component="td"
                                scope="row"
                                className={classes.cell}
                                align="left"
                            >
                              None
                            </TableCell>
                            <TableCell> - </TableCell>
                            <TableCell>(0, 0)</TableCell>
                            <TableCell className={classes.cell}>
                              <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled
                                  endIcon={<Icon src={editBtnIcon} />}
                              >
                                Edit Connection
                              </Button>
                              <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled
                              >
                                Remove Connection
                              </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <Switch color="primary" size="small" disabled />
                            </TableCell>
                            <TableCell>0%</TableCell>
                          </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Box>
              {reviewSites.length > 0 && activeReviewSites.length > 0 && (
                  <>
                    <SectionComponent marginTop="30px" width="330px">Step 3 - External Review Options</SectionComponent>
                    <Box display={"flex"} flexDirection={"row"} gridGap={"1rem"}>
                      <FeedbackOption name={"ReviewSites"} isActive={true} isSelected={selectedOption !== 'funnel'}
                                      onClick={() => {
                                        setSelectedOption('external')
                                      }}
                                      tooltipText={"Positive feedback directs users straight to the review site(s) added in the \"External Review Form\" setup."}
                                      isDisabled={reviewSites.length === 0}
                      />
                      <FeedbackOption name={"ReviewFunnel"} isActive={true} isSelected={selectedOption === 'funnel'}
                                      onClick={() => {
                                        setSelectedOption('funnel')
                                      }}
                                      isDisabled={reviewSites.length === 0}
                                      tooltipText={"Positive feedback directs users to a confirmation page. Ratings of 4-stars or higher are redirected to your review profile. (Ideal for prioritizing high ratings when receiving a high volume of reviews)."}
                      />
                    </Box>
                    <Button className={classes.previewBtn}
                            href={getHrefLocation() ?? '#'}
                            target="_blank">Preview Click</Button>
                  </>)}
                </>
              )}

        <ReviewSiteModal
            open={reviewSiteModalOpen}
            onClose={() => {
              setReviewSiteModalOpen(false);
              setSelectedReviewSite(undefined);
              setModalAction("");
            }}
            action={modalAction}
            selectedReviewSite={selectedReviewSite}
            getReviewSiteName={getReviewSiteName}
            reviewSiteOptions={reviewSiteOptions.filter(option => {
              return !reviewSites.some((site: ReviewSiteModel) => site.reviewSiteId !== 3 && site.reviewSiteId === option.id)
            })}
            organization={organization}
            addReviewSite={(reviewSite) => addReviewSite({ reviewSite, type: "email-signature" })}
            deleteReviewSite={(id) => {
              deleteReviewSite({id, type: "email-signature"});
            }}
            toggleSurveyFormIsActive={toggleSurveyFormIsActive}
        />
        <SnackbarComponent
            isOpen={selectedPlaceConfirmed}
            msg="INTEGRATION COMPLETE"
            severity="success"
            handleClose={() => props.setAlertNotification(false)}
        />
      </>
  );
};

export default ReviewsPage;
