import {Box, Button, Menu, MenuItem} from "@material-ui/core";
import qrCodeAppIcon from "../../svg/rms-qr-code-app-icon.svg";
import emailSignatureWidgetIcon from "../../svg/rms-email-signature-widget-icon.svg";
import agencyAreaIcon from "../../svg/rms-agency-area-icon.svg";
import appsIcon from "../../svg/apps-icon.svg";
import downArrowIcon from "../../svg/down-arrow-icon.svg";
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {Link} from "react-router-dom";
import {ApplicationState} from "../../ApplicationState";
import {connect} from "react-redux";
import * as sharedSelectors from "../sharedSelectors";
import {UserProfileModel} from "../SharedModels";
import * as selectors from "../../AccountPage/accountSelectors";

const useStyles = makeStyles(() => ({
    menuItemBox: {
        color: "#000",
        marginLeft: "14px",
        fontWeight: 300,
        fontSize: "16px"
    },
    menuItem: {
        borderBottom: "2px solid #f2f2f2",
    },
    buttonItem: {
        display: "flex",
        alignItems: "center"
    },
    button: {
        border: "1px solid #cccccc",
        borderRadius: "7px",
        padding: "10px 14px",
        display: "flex",
        alignItems: "center",
    },
    menuRoot: {
        "& .MuiMenu-list": {
            padding: 0
        }
    }
}));

type AppsDropdownProps = {
    userProfile: UserProfileModel | undefined,
    primaryOrgId: number,
    isPrimaryOrg: boolean
}

function AppsDropdownComponent(props: AppsDropdownProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const {userProfile, isPrimaryOrg} = props;

    const orgId = userProfile ? userProfile.organization.id : 0;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    }

    const isPrimaryOrgPage = userProfile?.organizationId === props.primaryOrgId;

    return (
        <div style={{marginLeft: "8px"}}>
            <Button onClick={handleClick} className={classes.button}>
                <Box className={classes.buttonItem}>
                    <img src={appsIcon} alt="apps icon"/>
                </Box>
                <Box className={classes.buttonItem}
                     style={{marginLeft: "14px", marginRight: "6px", fontSize: "16px"}}><strong>Apps</strong></Box>
                <Box className={classes.buttonItem}>
                    <img src={downArrowIcon} alt="down arrow icon"/>
                </Box>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}
                  PaperProps={{
                      style: {
                          marginTop: 39,
                          backgroundColor: "#FFF",
                          marginLeft: -2,
                          border: "1px solid #cccccc",
                      },
                  }}
                  classes={{paper: classes.menuRoot}}
                  anchorReference="anchorEl">
                <MenuItem className={classes.menuItem} component={Link} to={`/org${orgId}/setup/customize`}
                          onClick={handleClose}>
                    <Box>
                        <img src={emailSignatureWidgetIcon} alt="email signature widget icon"/>
                    </Box>
                    <Box className={classes.menuItemBox}><strong>Email Signature Widget</strong></Box>
                </MenuItem>
                <MenuItem className={classes.menuItem} component={Link} to={`/org${orgId}/QRCodeApp/setup/feedback`}
                          onClick={handleClose}>
                    <Box>
                        <img src={qrCodeAppIcon} alt="qr code app icon"/>
                    </Box>
                    <Box className={classes.menuItemBox}><strong>QR Code App</strong></Box>
                </MenuItem>
                {isPrimaryOrg && isPrimaryOrgPage ? <MenuItem component={Link} to={`/org${orgId}/settings`} onClick={handleClose}>
                    <Box>
                        <img src={agencyAreaIcon} alt="agency area icon"/>
                    </Box>
                    <Box className={classes.menuItemBox}><strong>Agency Area</strong></Box>
                </MenuItem> : null}
            </Menu>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        userProfile: sharedSelectors.profileSelector(state),
        primaryOrgId: selectors.primaryOrgIdSelector(state)
    };
};

const AppsDropdown = connect(
    mapStateToProps
)(AppsDropdownComponent);

export default AppsDropdown;