import React, {useEffect, useState} from 'react';
import {Box, Container, FormControl, MenuItem, Select, Typography} from '@material-ui/core';
import MenuBarComponent from './MenuBarComponent';
import {RouteComponentProps, withRouter} from 'react-router';
import RMSLogo from '../../svg/RateMyService-logo-new.svg'
import AgencyDropdownComponent from "./AgencyDropdownComponent";
import {WhiteLabelProfile} from "../SharedModels";
import {ApplicationState} from "../../ApplicationState";
import * as sharedSelectors from "../sharedSelectors";
import {connect} from "react-redux";
import {appSettings} from "../../config/appSettings";
import AppsDropdown from "./AppsDropdown";

const staticDataUrl = appSettings.app.staticDataUrl;

type MenuBarProps = {
    agentSelect?: boolean;
    whiteLabel?: WhiteLabelProfile
}

const MenuBar: React.FC<RouteComponentProps & MenuBarProps> = (props) => {
    const [logoSrc, setLogoSrc] = useState<string>("");
    const {agentSelect} = props;

    useEffect(() => {
        let logoSrc = RMSLogo;
        if (props.whiteLabel && props.whiteLabel.agencyLogo) {
            logoSrc = staticDataUrl + props.whiteLabel.agencyLogo;
        }
        setLogoSrc(logoSrc);
    }, [props.whiteLabel]);

    return (
        <Box className="App-header">
            <Box className="App-header-group">
                <AppsDropdown isPrimaryOrg={agentSelect}/>
                {logoSrc && <img src={logoSrc} style={{width: "195px", maxHeight: "50px", objectFit: "contain"}} alt={"Company Logo"} />}
                {agentSelect && <AgencyDropdownComponent/>}
            </Box>
            <MenuBarComponent isPrimaryOrg={agentSelect} />
        </Box>
    )
}
const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        whiteLabel: sharedSelectors.whiteLabelSelector(state),
    }
}
const MenuBarContainer = connect(
    mapStateToProps,
    null
)(MenuBar);
export default withRouter(MenuBarContainer);
