import React, {useState, useEffect} from 'react';
import { useDebounce } from "use-debounce";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import {
    AutocompleteSuggestion,
    OutscaperResponse,
    PlaceDetailsModel,
    ReviewSiteModel,
    ReviewSiteOptionModel
} from "../SetupModels";
import {appSettings} from "../../config/appSettings";
import {makeStyles} from "@material-ui/core/styles";
import Loading from "../../shared/components/Loading";
import GoogleLogo from "../../png/google_on_white.png";
import {OrganizationModel} from "../../shared/SharedModels";
import {Organization} from "../../shared/SharedData";

const apiName = appSettings.apisMetadata.find((x) => x.id === "rms")!.name;
const apiUrlRoot = appSettings.apisMetadata.find(
    (x) => x.id === "rms"
)!.baseAddress;
interface ReviewSiteModalProps {
    open: boolean;
    onClose: () => void;
    action: string;
    selectedReviewSite: ReviewSiteModel | undefined;
    getReviewSiteName: (id: number | undefined) => string;
    reviewSiteOptions: ReviewSiteOptionModel[];
    organization: OrganizationModel | undefined;
    addReviewSite: (reviewSite: ReviewSiteModel, type?: string) => void;
    deleteReviewSite: (id: number) => void;
    toggleSurveyFormIsActive: (organisation: Organization) => void;
    type?: string
}

const initialPlace: PlaceDetailsModel = {
    id: "",
    name: "",
    rating: 0,
    reviews: [],
    url: "",
    ratingsTotal: 0,
};

const useStyles = makeStyles({
    btnGroup: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    grayButton: {
        color: "#8f8f8f",
        borderColor: "#8f8f8f",
        marginRight: "0.5rem",
        '&:hover': {
            color: "#424242",
            borderColor: "#424242",
            backgroundColor: "transparent",
        }
    },
    redButton: {
        color: "#F43B3B",
        borderColor: "#F43B3B",
        marginRight: "0.5rem",
        '&:hover': {
            color: "#fa7a7a",
            borderColor: "#fa7a7a",
            backgroundColor: "transparent",
        }
    },
    dialog: {
        height: "100vh",
    },
    content: {
        height: "40vh",
        minHeight: "300px",
    },
    testBtn: {
        marginLeft: "10px",
    },
    placeDetails: {
        marginTop: "5rem",
        width: "40%",
        borderRadius: "4px",
    },
    placeDetailsRow: {
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    searchInput: {
        listStyle: "none",
    },
    autocompleteBox: {
        display: "flex",
    },
    autocompleteInput: {
        width: "100%",
    },
    googleLogo: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "1rem",
        fontFamily: "Roboto",
        alignItems: "center",
    },
    googleLogoImg: {
        paddingLeft: ".5rem",
    },
    finalMessage: {
        fontSize: "1.75rem",
        textAlign: "center",
        padding: "2.5rem",
    },
});
const ReviewSiteModal = (props: ReviewSiteModalProps) => {
    const classes = useStyles();
    const {reviewSiteOptions, action} = props;
    let title = "Review Site";
    let dialogContent = null;

    const uuid = uuidv4();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("Loading...");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [setupStep, setSetupStep] = useState<string>("selectType");
    const [prevStep, setPrevStep] = useState<string>("");
    const [nextStep, setNextStep] = useState<string>("");
    const [selectedReviewOption, setSelectedReviewOption] = useState<ReviewSiteOptionModel | null>(null);

    const [sessionUUID, setSessionUUID] = useState<string>("");

    const [selectedId, setSelectedId] = useState<string>("");
    const [selectedPlace, setSelectedPlace] = useState<PlaceDetailsModel>(initialPlace);
    const [suggestions, setSuggestions] = useState<AutocompleteSuggestion[]>([]);
    const [outscraperSuggestions, setOutscraperSuggestions] = useState<OutscaperResponse[]>([]);
    const [inputField, setInputField] = useState<string>("");
    const [altField, setAltField] = useState<string>(""); // should there be a second parameter
    const [debouncedInputField] = useDebounce(inputField, 1000);
    const [tested, setTested] = useState<boolean>(false);

    const isValidUrl = (urlString: string): boolean => {
        try {
            const urlObject = new URL(urlString);
            const domainName = urlObject.hostname?.replace(/\/+$/, "") ?? "";
            return isValidDomain(domainName);
        } catch (_) {
            return false;
        }
    };

    const isValidDomain = (domainName: string): boolean => {
        const urlRegex = /^(?:https?:\/\/)?(?:[\w-]+\.)+[a-z]{2,}$/i;
        return urlRegex.test(domainName);
    };

    const fetchGoogleDetails = async () => {
        setLoadingSuggestions(true);
        setTested(false);
        const url =
            apiUrlRoot + `/${apiName}/review/get-place/${selectedId}/${sessionUUID}`;
        const tokenObj = JSON.parse(localStorage.getItem("api")!);
        const token = tokenObj["access_token"];

        const options: RequestInit = {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        };

        if (selectedId !== "" && selectedId !== null) {
            await fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    const details = data.result;
                    if (details.reviews) {
                        setSelectedPlace({
                            id: selectedId,
                            reviews: details.reviews,
                            name: details.name,
                            rating: details.rating,
                            url: details.url,
                            ratingsTotal: details.user_ratings_total,
                        });
                        setInputField(details.name);
                        setSuggestions([]);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setLoadingSuggestions(false);
                });
        }
    };

    const fetchOutscraperSuggestions = async () => {
        setSuggestions([]);
        setOutscraperSuggestions([]);
        setLoadingSuggestions(true);
        if (inputField === "" || !selectedReviewOption || errorMessage !== "") {
            return;
        }
        let url =
            apiUrlRoot + `/${apiName}/review/get-site-suggestions/${selectedReviewOption.id}/${encodeURIComponent(debouncedInputField)}`;
        if (selectedReviewOption.name === "YELP" && altField) {
            url = url + `?location=${encodeURIComponent(altField)}`;
        }
        const tokenObj = JSON.parse(localStorage.getItem("api")!);
        const token = tokenObj["access_token"];

        const options: RequestInit = {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        };

        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setOutscraperSuggestions(data);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoadingSuggestions(false);
            });
    }

    const fetchSuggestions = async () => {
        setSuggestions([]);
        setLoadingSuggestions(true);
        if (inputField === "") {
            return;
        }
        const url =
            apiUrlRoot + `/${apiName}/review/get-suggestions/${debouncedInputField}/${sessionUUID}`;

        const tokenObj = JSON.parse(localStorage.getItem("api")!);
        const token = tokenObj["access_token"];

        const options: RequestInit = {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        };

        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                const {predictions} = data;
                const suggestions = predictions.map((x: any) => {
                    return {
                        description: x.description,
                        place_id: x.place_id,
                    };
                });
                setSuggestions(suggestions);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoadingSuggestions(false);
            });

    };

    const handleConfirm = () => {
        const {addReviewSite, type} = props;
        let model = {} as ReviewSiteModel;
        if (selectedReviewOption?.name === "Custom URL") {
            const urlObject = new URL(inputField);
            const domainName = urlObject.hostname?.replace(/\/+$/, "") ?? "";
            model = {
                reviewSiteId: 3,
                identifier: "customLinkReviewSite",
                name: domainName,
                rating: 0,
                ratingsTotal: 0,
                url: inputField ?? "",
                isActive: true,
            };
        }
        if (selectedReviewOption?.name === "Facebook" || selectedReviewOption?.name === "Trustpilot" || selectedReviewOption?.name === "YELP") {
            model = {
                reviewSiteId: selectedReviewOption.id,
                identifier: selectedPlace.id,
                name: selectedPlace.name,
                rating: selectedPlace.rating === undefined ? 0 : selectedPlace.rating,
                ratingsTotal:
                    selectedPlace.ratingsTotal === undefined
                        ? 0
                        : selectedPlace.ratingsTotal,
                url: selectedPlace.url,
                isActive: true,
            };
        }
        if (selectedReviewOption?.name === "Google") {
            model = {
                reviewSiteId: 2,
                identifier: selectedPlace.id,
                name: selectedPlace.name,
                rating: selectedPlace.rating === undefined ? 0 : selectedPlace.rating,
                ratingsTotal:
                    selectedPlace.ratingsTotal === undefined
                        ? 0
                        : selectedPlace.ratingsTotal,
                url: selectedPlace.url,
                isActive: true,
            };
        }

        if (Object.keys(model).length > 0) {
            addReviewSite(model, type);
            setInputField("");
            handleClose();
        }
    };

    const handleConfirmDelete = () => {
        const {
            selectedReviewSite,
            deleteReviewSite,
            organization,
            toggleSurveyFormIsActive
        } = props;

        if (selectedReviewSite !== undefined) {
            if (organization) {
                if (!organization.surveyFormIsActive) {
                    const model = {
                        ...organization,
                        surveyFormIsActive: true,
                    };
                    toggleSurveyFormIsActive(model as unknown as Organization);
                }
            }
            if (selectedReviewSite.id !== undefined) {
                deleteReviewSite(selectedReviewSite.id);
            }
        }
    };

    useEffect(() => {
        if (action === "remove" || action === "edit") {
            setSetupStep("removeSite");
            setNextStep("handleConfirmRemove");
        }
    }, [action])

    useEffect(() => {
        if (setupStep === "selectType") {
            setInputField("");
            setPrevStep("");
        }
        if (setupStep === "google" || setupStep === "facebook" || setupStep === "trustpilot" || setupStep === "yelp") {
            setSuggestions([]);
            setOutscraperSuggestions([]);
            setPrevStep("selectType");
        }
        if (setupStep === "confirmGoogle") {
            setPrevStep("google");
            setNextStep("handleConfirmation");
        }
        if (setupStep === "confirmOutscraper") {
            switch(selectedReviewOption?.name) {
                case "Facebook":
                    setPrevStep("facebook");
                    break;
            }
            setNextStep("handleConfirmation");
        }
        if (setupStep === "confirmCustomLink") {
            setPrevStep("customLink");
            setNextStep("handleConfirmation");
        }
        if (setupStep === "handleConfirmation") {
            handleConfirm();
            handleClose();
        }
        if (setupStep === "handleConfirmRemove") {
            handleConfirmDelete();
            if (action === "edit") {
                if (props.selectedReviewSite?.identifier === "customLinkReviewSite") {
                    const reviewOption = reviewSiteOptions.find(x => x.name === "Custom URL");
                    if (reviewOption) {
                        setSelectedReviewOption(reviewOption);
                        setInputField(props.selectedReviewSite.url);
                        setSetupStep("customUrl");
                    }

                } else {
                    setSessionUUID(uuid);
                    const reviewOption = reviewSiteOptions.find(x => x.name === "Google");
                    if (reviewOption) {
                        setSelectedReviewOption(reviewOption);
                        if (props.selectedReviewSite?.name) {
                            setInputField(props.selectedReviewSite.name);
                            setSetupStep("google");
                            fetchSuggestions();
                        }
                    }
                }
                setNextStep("");
                setPrevStep("");
            }
            if (action === "remove") {
                handleClose();
            }

        }
    }, [setupStep]);

    useEffect(() => {
        if (debouncedInputField === "") {
            setSuggestions([]);
            setSelectedId("");
            setSelectedPlace(initialPlace);
            setTested(false);
        }

        if (isValidUrl(debouncedInputField)) {
            setErrorMessage("Please enter a valid page name, not a domain name");
        } else {
            setErrorMessage("");
        }

        if (setupStep === "google" && debouncedInputField !== "" && selectedPlace.name !== debouncedInputField) {
            fetchSuggestions();
        }
    }, [debouncedInputField]);

    useEffect(() => {
        if (selectedId !== "" && !tested && selectedReviewOption?.name === "Google") {
            fetchGoogleDetails();
        } else {
            const selectedSuggestion  = outscraperSuggestions.find(x  => x.identifier === selectedId);
            if (selectedSuggestion) {
                setSelectedPlace({
                    id: selectedSuggestion.identifier,
                    name: selectedSuggestion.name,
                    rating: selectedSuggestion.rating,
                    reviews: [],
                    url: selectedSuggestion.url,
                    ratingsTotal: selectedSuggestion.reviews,
                });
                setInputField(selectedSuggestion.name);
                setSuggestions([]);
                setOutscraperSuggestions([]);
            }
        }
    }, [selectedId]);

    const handleClose = () => {
        setPrevStep("");
        setNextStep("");
        setSetupStep("selectType");
        setTested(false);
        setSelectedReviewOption(null);
        setSelectedId("");
        setSelectedPlace(initialPlace);
        props.onClose();
    }

    const handleOption = (
        event: React.ChangeEvent<{ name?: string; value: any }>
    ) => {
        if (!event.target.name) {
            return;
        }
        const {reviewSiteOptions} = props;

        const [site] = reviewSiteOptions.filter(
            (site) => site.id === event.target.value
        );
        switch(site.name) {
            case "Facebook":
                setNextStep('facebook');
                break;
            case "YELP":
                setNextStep('yelp');
                break;
            case "Trustpilot":
                setNextStep('trustpilot');
                break;
            case "Google":
                setNextStep('google');
                setSessionUUID(uuid);
                break;
            case "Custom URL":
                setNextStep('customUrl');
                break;
        }
        setSelectedReviewOption(site);
    };

    // OPEN SELECTED PLACE IN NEW WINDOW

    const testLink = () => {
        let target: string = "popup";
        let url = "";
        if (!selectedReviewOption) return;
        switch (selectedReviewOption.name) {
            case "Custom URL":
                url = inputField;
                setNextStep("confirmCustomLink");
                break;
            case "Google":
                url = selectedPlace ? selectedPlace.url : "";
                setNextStep("confirmGoogle");
                break;
            case "Facebook":
            case "Trustpilot":
            case "YELP":
                url = selectedPlace ? selectedPlace.url : "";
                setNextStep("confirmOutscraper");
                break;
            default:
                // Handle other cases or do nothing
                break;
        }
        if (url !== "") {
            window.open(url, target, "width=800,height=600");
            setTested(true);

        }
    };

    if (loading) {
        dialogContent = <Loading message={loadingMessage} />;
    }
    switch(setupStep) {
        case "selectType":
        default:
            title = 'Add Review Site';
            dialogContent = (
                <>
                    <DialogContentText>
                        Select a Review Site to add to your Organisation
                    </DialogContentText>
                    <Select
                        variant="outlined"
                        id="reviewSite"
                        name="review-site"
                        displayEmpty
                        value={selectedReviewOption ? selectedReviewOption.id : ""}
                        className={`select-holder`}
                        onChange={handleOption}
                    >
                        {reviewSiteOptions &&
                            reviewSiteOptions.map((site) => (
                                <MenuItem key={site.id} value={site.id}>
                                    {site.name ? site.name : <i>_None_</i>}
                                </MenuItem>
                            ))}
                    </Select>
                </>
            );
            break;
        case "customUrl":
            title = `${action === "edit" ? "Edit" : "Add"} Custom URL`;
            dialogContent = (
                <>
                    <DialogContentText>Custom Review Site URL</DialogContentText>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        className={classes.autocompleteBox}
                        my={2}
                    >
                        <div className={classes.autocompleteInput}>
                            <TextField
                                name="autocomplete"
                                value={inputField}
                                id="autocomplete"
                                variant="outlined"
                                placeholder="Enter your custom URL e.g. https://www.facebook.com/vidadurbanville/reviews"
                                onChange={(e) => {
                                    setInputField(e.target.value);
                                }}
                                style = {{ fontSize: "14px" }}
                            />
                        </div>
                        <div>
                            {!tested && (
                                <Button
                                    className={classes.testBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={testLink}
                                    disabled={!isValidUrl(inputField)}
                                >
                                    Test
                                </Button>
                            )}
                        </div>
                    </Box>
                    {(isValidUrl(inputField) && !tested) && (
                        <DialogContentText>
                            Click 'Test' to confirm the correct listing or retype the URL to search again
                        </DialogContentText>
                    )}
                </>
            );
            break;
        case "facebook":
            title = `${action === "edit" ? "Edit" : "Add"} Facebook Site`;
            dialogContent = (
                <>
                    {(selectedId && selectedPlace.name !== "") && (
                        <Box fontWeight={"bold"} p={2} textAlign={"center"} >
                            {selectedPlace.name} has {selectedPlace.rating} ratings average from {selectedPlace.ratingsTotal} reviews.
                        </Box>
                    )}
                    {errorMessage && (
                        <Box p={2} textAlign={"center"} color={"red"}>
                            {errorMessage}
                        </Box>
                    )}
                    <DialogContentText>Facebook page name: i.e www.facebook.com/<span style={{color: "blue"}}>page name</span></DialogContentText>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        className={classes.autocompleteBox}
                        my={2}
                    >
                        <div className={classes.autocompleteInput}>
                            <TextField
                                name="autocomplete"
                                value={inputField}
                                id="autocomplete"
                                variant="outlined"
                                placeholder="Enter your Facebook page name"
                                onChange={(e) => {
                                    setInputField(e.target.value);
                                }}
                                style={{fontSize: "14px"}}
                            />
                        </div>
                        <div>
                            <Button
                                className={classes.testBtn}
                                variant="outlined"
                                color="primary"
                                onClick={fetchOutscraperSuggestions}
                                disabled={errorMessage !== ""}
                            >
                                Search
                            </Button>
                        </div>
                        <div>
                            {!tested && (
                                <Button
                                    className={classes.testBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={testLink}
                                    disabled={!isValidUrl(selectedPlace.url)}
                                >
                                    Test
                                </Button>
                            )}
                        </div>
                    </Box>
                    <Box>
                        <Typography variant="caption" display="block">
                            <b>Required:</b> www.facebook.com/<b>page name</b> (i.e, Only add the name from the URL)
                        </Typography>
                        <Typography variant="caption" display="block">
                            Alternately, until the above requirements are met you can add your Facebook profile as a 'Custom URL' (from the dropdown list) instead.
                        </Typography>
                    </Box>
                    {(isValidUrl(selectedPlace.url) && !tested) && (
                        <DialogContentText>
                            Click 'Test' to confirm the correct listing or retype the page name to search again
                        </DialogContentText>
                    )}
                    {loadingSuggestions && (
                        <Box py={2}>
                            <Loading message={"Getting Facebook page..."} />
                        </Box>
                    )}
                    {outscraperSuggestions.length > 0 && <DialogContentText>Select the correct listing below:</DialogContentText>}
                    <ul className={classes.searchInput}>
                        {outscraperSuggestions.length > 0 &&
                            outscraperSuggestions.map((item) => (
                                <li
                                    style={{ padding: "10px", cursor: "pointer" }}
                                    className="listItem"
                                    key={item.identifier}
                                    id={item.identifier}
                                    onClick={() => {
                                        setTested(false);
                                        setSelectedId(item.identifier);
                                    }}
                                >
                                    {item.name} ({item.reviews} reviews)
                                </li>
                            ))}
                    </ul>
                </>
            );
            break;
        case "trustpilot":
            title = `${action === "edit" ? "Edit" : "Add"} TrustPilot Site`;
            dialogContent = (
                <>
                    {(selectedId && selectedPlace.name !== "") && (
                        <Box fontWeight={"bold"} p={2} textAlign={"center"} >
                            {selectedPlace.name} has {selectedPlace.rating} ratings average from {selectedPlace.ratingsTotal} reviews.
                        </Box>
                    )}
                    {errorMessage && (
                        <Box p={2} textAlign={"center"} color={"red"}>
                            {errorMessage}
                        </Box>
                    )}
                    <DialogContentText>Trustpilot business name:</DialogContentText>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        className={classes.autocompleteBox}
                        my={2}
                    >
                        <div className={classes.autocompleteInput}>
                            <TextField
                                name="autocomplete"
                                value={inputField}
                                id="autocomplete"
                                variant="outlined"
                                placeholder="Enter your Trustpilot business name"
                                onChange={(e) => {
                                    setInputField(e.target.value);
                                }}
                                style={{fontSize: "14px"}}
                            />
                        </div>
                        <div>
                            <Button
                                className={classes.testBtn}
                                variant="outlined"
                                color="primary"
                                onClick={fetchOutscraperSuggestions}
                                disabled={errorMessage !== ""}
                            >
                                Search
                            </Button>
                        </div>
                        <div>
                            {!tested && (
                                <Button
                                    className={classes.testBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={testLink}
                                    disabled={!isValidUrl(selectedPlace.url)}
                                >
                                    Test
                                </Button>
                            )}
                        </div>
                    </Box>
                    {(isValidUrl(selectedPlace.url) && !tested) && (
                        <DialogContentText>
                            Click 'Test' to confirm the correct listing or retype the page name to search again
                        </DialogContentText>
                    )}
                    {loadingSuggestions && (
                        <Box py={2}>
                            <Loading message={"Getting Trustpilot results..."} />
                        </Box>
                    )}
                    {outscraperSuggestions.length > 0 && <DialogContentText>Select the correct listing below:</DialogContentText>}
                    <ul className={classes.searchInput}>
                        {outscraperSuggestions.length > 0 &&
                        outscraperSuggestions.map((item) => (
                            <li
                                style={{ padding: "10px", cursor: "pointer" }}
                                className="listItem"
                                key={item.identifier}
                                id={item.identifier}
                                onClick={() => {
                                    setTested(false);
                                    setSelectedId(item.identifier);
                                }}
                            >
                                {item.name} ({item.reviews} reviews)
                            </li>
                        ))}
                    </ul>
                </>
            );
            break;
        case "yelp":
            title = `${action === "edit" ? "Edit" : "Add"} Yelp Site`;
            dialogContent = (
                <>
                    {(selectedId && selectedPlace.name !== "") && (
                        <Box fontWeight={"bold"} p={2} textAlign={"center"} >
                            {selectedPlace.name} has {selectedPlace.rating} ratings average from {selectedPlace.ratingsTotal} reviews.
                        </Box>
                    )}
                    {errorMessage && (
                        <Box p={2} textAlign={"center"} color={"red"}>
                            {errorMessage}
                        </Box>
                    )}
                    <DialogContentText>Yelp business name:</DialogContentText>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        className={classes.autocompleteBox}
                        my={2}
                    >
                        <div className={classes.autocompleteInput}>
                            <TextField
                                name="autocomplete"
                                value={inputField}
                                id="autocomplete"
                                variant="outlined"
                                placeholder="Enter your Yelp business name"
                                onChange={(e) => {
                                    setInputField(e.target.value);
                                }}
                                style={{fontSize: "14px"}}
                            />
                        </div>
                        <div className={classes.autocompleteInput}>
                            <TextField
                                name="autocomplete"
                                value={altField}
                                id="autocomplete"
                                variant="outlined"
                                placeholder="Location (optional)"
                                onChange={(e) => {
                                    setAltField(e.target.value);
                                }}
                                style={{fontSize: "14px"}}
                            />
                        </div>
                        <div>
                            <Button
                                className={classes.testBtn}
                                variant="outlined"
                                color="primary"
                                onClick={fetchOutscraperSuggestions}
                                disabled={errorMessage !== ""}
                            >
                                Search
                            </Button>
                        </div>
                        <div>
                            {!tested && (
                                <Button
                                    className={classes.testBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={testLink}
                                    disabled={!isValidUrl(selectedPlace.url)}
                                >
                                    Test
                                </Button>
                            )}
                        </div>
                    </Box>
                    {(isValidUrl(selectedPlace.url) && !tested) && (
                        <DialogContentText>
                            Click 'Test' to confirm the correct listing or retype the page name to search again
                        </DialogContentText>
                    )}
                    {loadingSuggestions && (
                        <Box py={2}>
                            <Loading message={"Getting Yelp results..."}/>
                        </Box>
                    )}
                    {outscraperSuggestions.length > 0 &&
                        <DialogContentText>Select the correct listing below:</DialogContentText>}
                    <ul className={classes.searchInput}>
                        {outscraperSuggestions.length > 0 &&
                        outscraperSuggestions.map((item) => (
                            <li
                                style={{ padding: "10px", cursor: "pointer" }}
                                className="listItem"
                                key={item.identifier}
                                id={item.identifier}
                                onClick={() => {
                                    setTested(false);
                                    setSelectedId(item.identifier);
                                }}
                            >
                                {item.name} ({item.reviews} reviews)
                            </li>
                        ))}
                    </ul>
                </>
            )
            break;
        case "google":
            title = `${action === "edit" ? "Edit" : "Add"} Google Site`;
            dialogContent = (
                <>
                {(selectedId && selectedPlace.name !== "") && (
                    <Box fontWeight={"bold"} p={2} textAlign={"center"} >
                        {selectedPlace.name} has {selectedPlace.rating} ratings average from {selectedPlace.ratingsTotal} reviews.
                    </Box>
                )}
                <DialogContentText>Google business name:</DialogContentText>
                <Box className={classes.autocompleteBox}>
                    <div className={classes.autocompleteInput}>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            className={classes.autocompleteBox}
                            my={2}
                        >
                            <div className={classes.autocompleteInput}>
                                <TextField
                                    name="autocomplete"
                                    value={inputField}
                                    id="autocomplete"
                                    variant="outlined"
                                    placeholder="Enter your organization name"
                                    onChange={(e) => setInputField(e.target.value)}
                                    inputProps={{autoComplete: "off"}}
                                />
                            </div>
                            {!tested && (
                                <Button
                                    className={classes.testBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={testLink}
                                    disabled={!isValidUrl(selectedPlace.url)}
                                >
                                    Test
                                </Button>
                            )}
                        </Box>
                        <Box>
                            <Typography variant="caption" display="block">
                                <b>Required:</b> In order to connect your Google profile, you must have an address (I.e. cannot be a Service Area), and have at least 1 review.
                            </Typography>
                            <Typography variant="caption" display="block">
                                Alternately, until the above requirements are met you can add your Google profile as a 'Custom URL' (from the dropdown list) instead.
                            </Typography>
                        </Box>

                        {(isValidUrl(selectedPlace.url) && !tested) && (
                            <DialogContentText>
                                Click 'Test' to confirm the correct listing or retype the place to search again
                            </DialogContentText>
                        )}
                        {loadingSuggestions && (
                            <Box py={2}>
                                <Loading message={"Getting Google suggestions..."} />
                            </Box>
                        )}
                        <ul className={classes.searchInput}>
                            {suggestions.length > 0 &&
                                suggestions.map((item) => (
                                    <li
                                        style={{ padding: "10px", cursor: "pointer" }}
                                        className="listItem"
                                        key={item.place_id}
                                        id={item.place_id}
                                        onClick={() => {
                                            setTested(false);
                                            setSelectedId(item.place_id);
                                        }}
                                    >
                                        {item.description}
                                    </li>
                                ))}
                            <li className={classes.googleLogo}>
                                Powered by{" "}
                                <img
                                    className={classes.googleLogoImg}
                                    src={GoogleLogo}
                                    alt="Google logo"
                                />
                            </li>
                        </ul>
                    </div>
                </Box>
                </>
            );
            break;
        case "confirmGoogle":
            title = 'Confirm Google Site';
            dialogContent = (
                <DialogContentText className={classes.finalMessage}>
                    Thank you for activating the
                    <Box display={"block"} py={2}>
                        Reviews Module!
                    </Box>
                    <Box display={"block"} py={2}>
                        Please click 'Save' to complete the process.
                    </Box>
                    We will gather your Reviews behind the scenes and notify you by email as soon as they are loaded to your profile.
                </DialogContentText>
            );
            break;
        case "confirmCustomLink":
            title = 'Confirm Custom Site';
            dialogContent = (
                <DialogContentText className={classes.finalMessage}>
                    <Box display={"block"} py={2}>
                        Please click 'Save' to complete the process.
                    </Box>
                </DialogContentText>
            );
            break;
        case "confirmOutscraper":
            title = `Confirm ${selectedReviewOption?.name} Site`;
            dialogContent = (
                <DialogContentText className={classes.finalMessage}>
                    <Box display={"block"} py={2}>
                        Please click 'Save' to complete the process.
                    </Box>
                </DialogContentText>
            );
            break;
        case "removeSite":
            title = `${action === "edit" ? "Edit" : "Remove"} your review Site`;
            dialogContent = (
                <DialogContentText className={classes.finalMessage}>
                    <Box>
                        <Typography variant={"h1"}>CAUTION!</Typography>
                    </Box>
                    <Box display={"block"} py={2}>
                        <Typography variant={"body1"} paragraph={true}>
                        The next step will REMOVE the EXISTING connection between the app and the Review Site.
                        Once done, you can then select a NEW Review Site connection.
                        </Typography>
                        <Typography variant={"body1"}>
                        Click 'Confirm' if you are sure you want to proceed!
                        </Typography>
                    </Box>
                </DialogContentText>
            );
            break;
    }

    return (
      <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
      >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent dividers={true}>
            {dialogContent}
          </DialogContent>
          <DialogActions>
              <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
              >
                  <Button
                      variant="outlined"
                      onClick={handleClose}
                      className={setupStep === "removeSite" ? classes.grayButton : classes.redButton}
                  >
                      Cancel
                  </Button>
                  <Box display={"flex"} flexDirection={"row"} gridGap={"1rem"}>
                      {prevStep !== "" && (
                          <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                  setNextStep(setupStep);
                                  setSetupStep(prevStep);
                              }}
                          >
                              Back
                          </Button>
                      )}
                      <Button
                          color="secondary"
                          variant={setupStep === "removeSite" ? 'outlined' : 'contained'}
                          className={setupStep === "removeSite" ? classes.redButton : ''}
                          disabled={nextStep === ""}
                          onClick={() => {
                              setPrevStep(setupStep);
                              setSetupStep(nextStep);
                              setNextStep("");
                          }}
                      >
                          {["confirmGoogle", "confirmCustomLink", "removeSite", "confirmOutscraper"].indexOf(setupStep) > -1 ? (setupStep === "removeSite") ? "Confirm" : "Save" : "Next"}
                      </Button>
                  </Box>
              </Box>
          </DialogActions>
      </Dialog>
    );
}

export default ReviewSiteModal;
